import { 
    SET_HEATMAP_MAX, SET_HEATMAP_MIN, SET_VOLCANO_X, SET_VOLCANO_Y_MIN, SET_VOLCANO_Y_MAX,
    SET_VOLCANO_P, SET_VOLCANO_FC, SET_VOLCANO_UNIQUE, SET_VOLCANO_TYPE, CLICKED_PROTEIN, LOADING_PROTEIN,
    ADD_CUSTOM_PROTEIN, ADD_CUSTOM_COMPOUND, REMOVE_CUSTOM_COMPOUND, REMOVE_CUSTOM_PROTEIN,
    SET_COMPOUND_SEARCH, SET_PROTEIN_SEARCH, SET_CUSTOM_HEATMAP, SET_SHOW_LABEL,
    CLEAR_CUSTOM_PROTEIN, CLEAR_CUSTOM_COMPOUND,
} from './resultsConstants'

import produce from 'immer'

const INITIAL_STATE = {}

export default produce ((draft = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_HEATMAP_MAX:
            const { heatmapMax } = action
            draft['heatmapMax'] = heatmapMax
            break
        
        case SET_HEATMAP_MIN:
            const { heatmapMin } = action
            draft['heatmapMin'] = heatmapMin
            break

        case SET_VOLCANO_X:
            const { volcanoX } = action
            draft['volcanoX'] = volcanoX
            break     

        case SET_VOLCANO_Y_MIN:
            const { volcanoYMin } = action
            draft['volcanoYMin'] = volcanoYMin
            break     

        case SET_VOLCANO_Y_MAX:
            const { volcanoYMax } = action
            draft['volcanoYMax'] = volcanoYMax
            break     
    
        case SET_VOLCANO_P:
            const { volcanoP } = action
            draft['volcanoP'] = volcanoP
            break     
    
        case SET_VOLCANO_FC:
            const { volcanoFC } = action
            draft['volcanoFC'] = volcanoFC
            break     

        case SET_VOLCANO_UNIQUE:
            const { volcanoUnique } = action
            draft['volcanoUnique'] = volcanoUnique
            break     
        
        case SET_VOLCANO_TYPE:
            const { volcanoType } = action
            draft['volcanoType'] = volcanoType
            break     
        
        case CLICKED_PROTEIN:
            const { clicked } = action
            draft['clicked'] = clicked
            break     
        
        case LOADING_PROTEIN:
            const { loading } = action
            draft['loading'] = loading
            break     

        case ADD_CUSTOM_PROTEIN:
            const { customProteinData } = action
            if (! draft['customProtein']) {
                draft['customProtein'] = {}
            }
            draft['customProtein'][customProteinData.id] = customProteinData
            break     
       
        case ADD_CUSTOM_COMPOUND:
            const { customCompoundData } = action
            if (! draft['customCompound']) {
                draft['customCompound'] = {}
            }
            draft['customCompound'][customCompoundData.id] = customCompoundData       
            break     

        case REMOVE_CUSTOM_COMPOUND:
            const { customCompoundId } = action
            delete draft['customCompound'][customCompoundId]
            break      

        case REMOVE_CUSTOM_PROTEIN:
            const { customProteinId } = action
            delete draft['customProtein'][customProteinId]            
            break    

        case SET_COMPOUND_SEARCH:
            const { compoundSearch } = action
            draft['compoundSearch'] = compoundSearch        
            break    
            
        case SET_PROTEIN_SEARCH:
            const { proteinSearch } = action
            draft['proteinSearch'] = proteinSearch        
            break    

        case SET_CUSTOM_HEATMAP:
            const { heatmapData } = action
            draft['customHeatmapData'] = heatmapData        
            break    

        case SET_SHOW_LABEL:
            const { showLabel } = action
            draft['showLabel'] = showLabel        
            break    

        case CLEAR_CUSTOM_COMPOUND:
            draft['customCompound'] = {}        
            break        

        case CLEAR_CUSTOM_PROTEIN:
            draft['customProtein'] = {}        
            break    

        default:
            break
        }

    return draft
})