export const BACKEND_URL = window.location.hostname === 'localhost'
    ? 'http://localhost:5000' 
    : window.location.hostname === 'proteomics.fischerlab.org'
    ? 'https://api.dfci-fischerlab.com'
    : 'https://api-dev.dfci-fischerlab.com'

export const USERS_URL = '/users/'
export const GROUPS_URL = '/groups/'
export const EXPERIMENTS_URL = '/experiments/'
export const EXPORT_EXPERIMENTS_URL = '/export_results/'
export const CONFIGURATION_URL = '/configuration/'
export const HEATMAP_URL = '/heatmap/'
export const INACTIVE_LOGIN_URL = '/inactive/'
export const HOME_URL = '/'

export const NO_AUTH_URLS = [HOME_URL, INACTIVE_LOGIN_URL]

export const LOGIN_ENDPOINT = `${BACKEND_URL}/oauth`
export const LOGOUT_ENDPOINT = `${BACKEND_URL}/logout`

export const ERROR_COLOR = '#FFFFFF'

export const PROTEIN_SEARCH_MIN = 3

export const ADMIN_EMAIL = 'proteomics.oauth@crystal.harvard.edu'
export const MAILTO = `mailto:${ADMIN_EMAIL}?subject=Application Access&body=To whom it may concern:%0D%0A%0D%0APlease make this account active so that it can access the DFCI Proteomics application at app.dfci-fischerlab.com.`