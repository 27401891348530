import configurationReducer from './configuration/configurationReducer'
import experimentsReducer from './experiments/experimentsReducer'
import groupsReducer from './groups/groupsReducer'
import usersReducer from './users/usersReducer'
import tokenReducer from './token/tokenReducer'
import resultsReducer from './results/resultsReducer'

import { combineReducers } from 'redux'

export default combineReducers ({
    configuration: configurationReducer,
    experiments: experimentsReducer,
    groups: groupsReducer,
    users: usersReducer,
    token: tokenReducer,
    results: resultsReducer,
})