import {
    SET_GROUPS,
    UPDATE_GROUPS,
    REMOVE_GROUP,
    SET_ACTIVE_GROUPS,
    UPDATE_ACTIVE_GROUP,
    REMOVE_ACTIVE_GROUP,
} from './groupsConstants'
import produce from 'immer'

const INITIAL_STATE = {}

export default produce ((draft = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_GROUPS:
            const { groups } = action
            draft['all'] =  groups
            break
        
        case UPDATE_GROUPS:
            const { groupId, data } = action
            draft['all'][groupId] = data
            break

        case SET_ACTIVE_GROUPS:
            const { activeGroups } = action
            draft['active'] =  activeGroups
            break
        
        case UPDATE_ACTIVE_GROUP:
            const { activeGroupId, activeData } = action
            draft['active'][activeGroupId] = activeData
            break

        case REMOVE_ACTIVE_GROUP:
            const { removeGroupId } = action
            delete draft['active'][removeGroupId]
            break
        
        case REMOVE_GROUP:
            const { delGroupId } = action
            delete draft['all'][delGroupId]
            break
 
        default:
            break
        }

    return draft
})