import React, { Component } from 'react';
import _ from 'lodash'
import Plot from 'react-plotly.js';
import { Spin } from 'antd'
import { connect } from 'react-redux'
import { getExperimentResultDataRequest, getHeatmapDataRequest } from   '../features/experiments/experimentsActions'
import {
  setHeatmapMax,
  setHeatmapMin,
} from '../features/results/resultsActions'
import AwesomeDebouncePromise from 'awesome-debounce-promise';
class Heatmap extends Component {
    state = {
      loading: true,
      xVals : [],
      yVals : [],
      zVals : [],
      customData: []
    }

    componentDidMount() {
      const { hits, experiments, token, getHeatmapDataRequest, setHeatmapMax, setHeatmapMin } = this.props
      let hitIds = _.map(hits, 'accession_id')

      getHeatmapDataRequest(token, experiments.searchedExperiment.meta.id, hitIds).then(resp => {

        if ( resp ) {
          const {data} = resp

          const zVals = _.map(data['heatmap_data'], obj => obj.slice(0, -data['heatmap_len'] - 2))          
          const maxVals = _.max(_.map(zVals, (obj) => _.max(obj)))
          const minVals = _.min(_.map(zVals, (obj) => _.min(obj)))
          const allVals = _.concat(maxVals, minVals)
          const scale = Math.abs(_.maxBy(allVals, obj => Math.abs(obj)))
          const customData = _.map(data['heatmap_data'], obj => {
            return obj.slice(data['heatmap_len'], -2)})

          setHeatmapMax(scale)
          setHeatmapMin(-scale)

          this.setState({
            xVals: data['heatmap_columns'].slice(0, -data['heatmap_len'] - 2),            
            yVals: _.map(data['heatmap_data'], obj => `${obj.slice(-2)[0].split(';')[0]} (${obj.slice(-1)[0]})`),
            zVals,
            loading: false,
            customData,
          })
        }
      })
    }

    componentDidUpdate(prevProps) {
      for( let key of Object.keys(this.props.results) )  {
        if( (! key.startsWith('custom') && ! key.startsWith('heatmap') && ! key.startsWith('volcanoY') && key !== 'volcanoX' && key !== 'showLabel')
        && prevProps.results[key] !== this.props.results[key] ) {
          this.setState({loading: true}, () => {
            const { hits, experiments, token, getHeatmapDataRequest, setHeatmapMax, setHeatmapMin } = this.props
            let hitIds = _.map(hits, 'accession_id')

            AwesomeDebouncePromise(getHeatmapDataRequest(token, experiments.searchedExperiment.meta.id, hitIds).then(resp => {

              if ( resp ) {
                const {data} = resp

                const zVals = _.map(data['heatmap_data'], obj => obj.slice(0, -data['heatmap_len'] - 2))
                const maxVals = _.max(_.map(zVals, (obj) => _.max(obj)))
                const minVals = _.min(_.map(zVals, (obj) => _.min(obj)))
                const allVals = _.concat(maxVals, minVals)
                const scale = Math.abs(_.maxBy(allVals, obj => Math.abs(obj)))
                const customData = _.map(data['heatmap_data'], obj => {
                  return obj.slice(data['heatmap_len'], -2)})

                setHeatmapMax(scale)
                setHeatmapMin(-scale)

                this.setState({
                  xVals: data['heatmap_columns'].slice(0, -data['heatmap_len'] - 2),
                  yVals: _.map(data['heatmap_data'], obj => `${obj.slice(-2)[0].split(';')[0]} (${obj.slice(-1)[0]})`),
                  zVals,
                  loading: false,
                  customData,
                })
              }
            }), 750)
          })
          break
        }
      }
    }

    render() {
      const { xVals, yVals, zVals, loading, customData } = this.state
      const { colorScheme, results } = this.props

      if ( loading ) {
        return <Spin style={{marginTop: 10}} />
      }

      const data = [
            {
              type: 'heatmap', 
              autocolorscale: false,
              colorscale: colorScheme,
              x: xVals, 
              y: yVals,           
              z: zVals,
              hoverongaps: false,
              zauto: false,
              zmax: results.heatmapMax,
              zmin: results.heatmapMin,
              name: '',
              customdata: customData,
              hovertemplate: `<b>Log<sub>2</sub>FC:</b> %{z}<br />
<b>Log<sub>10</sub>P-Value:</b> %{customdata}<br />
<b>Treatment:</b> %{x}<br />
<b>Accession:</b> %{y}<br />
              `
          },
        ]
        
        return <>
              <Plot
                config={{
                  displaylogo: false,
                  responsive: true,
                  toImageButtonOptions: {
                    format: 'svg',
                    filename:'hits_heatmap'
                  }
                } 
              }
                data={data}
                layout={{ 
                  margin: {t:0,r:0,l:20},
                  hovermode: 'closest',
                  autosize: true,
                  showlegend: false,
                  xaxis: {
                    title: 'Treatments',
                  },
                  yaxis: {
                    title: {
                      text: 'Hits',
                      standoff: 10,
                    },
                    automargin: true,

                  }
                }}
                useResizeHandler={true}
                style={{width: "100%"}}
              />
        </>
    }
}

const mapStateToProps = ( { experiments, results }) => {
  return { 
    experiments,
    results
  }
}
export default connect (mapStateToProps, {
  getExperimentResultDataRequest,
  getHeatmapDataRequest,
  setHeatmapMax, 
  setHeatmapMin,
}) (Heatmap)