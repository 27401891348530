import { 
    SET_CONFIGURATIONS, 
    UPDATE_CONFIGURATION, 
    REMOVE_CONFIGURATION,
    SET_ACTIVE_CONFIGURATIONS, 
    UPDATE_ACTIVE_CONFIGURATION, 
    REMOVE_ACTIVE_CONFIGURATION,
} from './configurationConstants'

import ApiService from '../apiService'

export const setConfigurations	 = (configurations) => {
    return {
        type: SET_CONFIGURATIONS,
        configurations,
    }
}

export const updateConfiguration = (configurationId, data) => {
    return {
        type: UPDATE_CONFIGURATION,
        configurationId,
        data,
    }
}

export const removeConfiguration = (delConfigId, data) => {
    return {
        type: REMOVE_CONFIGURATION,
        delConfigId,
        data,
    }
}

export const setActiveConfigurations = (activeConfigurations) => {
    return {
        type: SET_ACTIVE_CONFIGURATIONS,
        activeConfigurations,
    }
}

export const updateActiveConfiguration = (activeConfigurationId, activeData) => {
    return {
        type: UPDATE_ACTIVE_CONFIGURATION,
        activeConfigurationId,
        activeData,
    }
}

export const removeActiveConfiguration = (removeConfigurationId) => {
    return {
        type: REMOVE_ACTIVE_CONFIGURATION,
        removeConfigurationId,
    }
}

export const getAllConfigurations = (token) => async dispatch => {
    try {
        const getResponse = await ApiService.getConfigurations(token)
        const configurations = {}
        const active = {}

        if (getResponse.data) {
            getResponse.data.forEach(element => {
                configurations[ element.id ] = element
                if (element.active) {
                    active[ element.id ] = element
                }
            })
        }
        dispatch(setConfigurations(configurations))
        dispatch(setActiveConfigurations(active))

    } catch(error) {
        console.warn(error)
    }
}

export const updateConfigurationRequest = (token, data) => async dispatch => {
    try {
        const updateResponse = await ApiService.updateConfiguration(token,data)
        if (updateResponse) {
            dispatch(updateConfiguration(updateResponse.data.id, updateResponse.data))

            if (data.active || updateResponse.data.active) {
                dispatch(updateActiveConfiguration(updateResponse.data.id, updateResponse.data))
            } else if ( data.active === false ) {
                dispatch(removeActiveConfiguration(updateResponse.data.id))
            }
            return updateResponse.data
        }

    } catch(error) {
        console.warn(error)
    }
}

export const createConfigurationRequest = (token, data) => async dispatch => {
    try {
        const createResponse = await ApiService.createConfiguration(token, data)
        if (createResponse) {
            dispatch(updateConfiguration(createResponse.data.id, createResponse.data))
            dispatch(updateActiveConfiguration(createResponse.data.id, createResponse.data))
        }
        return createResponse.data

    } catch(error) {
        console.warn(error)
    }
}

export const deleteConfigurationRequest = (token, configId) => async dispatch => {
    try {
        const deleteResponse = await ApiService.deleteConfiguration(token, configId)
        if (deleteResponse) {
            dispatch(removeConfiguration(configId))
            dispatch(removeActiveConfiguration(configId))
        }
        return deleteResponse.data

    } catch(error) {
        console.warn(error)
    }
}