import {
    SET_TOKEN,
    TOGGLE_LOGIN_MODAL,
} from './tokenConstants'
import produce from 'immer'
import _ from 'lodash'

const INITIAL_STATE = {}

export default produce ((draft = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_TOKEN:
            const { token } = action
            draft['token'] =  token
            break
        case TOGGLE_LOGIN_MODAL:
            try {
                if (_.isNil(draft['showLogin'])) {
                    draft['showLogin'] = true
                } else {
                    draft['showLogin'] = ! draft['showLogin']
                }
            } catch {
                draft = {}
                localStorage.clear()
            }
            break
             
        default:
            break
        }
    
    return draft
})