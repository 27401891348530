import { 
    SET_GROUPS, UPDATE_GROUPS, REMOVE_GROUP,
    SET_ACTIVE_GROUPS, UPDATE_ACTIVE_GROUP, REMOVE_ACTIVE_GROUP,
} from './groupsConstants'

import {
    updateUser, updateActiveUser,
} from '../users/usersActions'

import {
    updateExperiment, updateActiveExperiment,
} from '../experiments/experimentsActions'

import ApiService from '../apiService'

export const setGroups = (groups) => {
    return {
        type: SET_GROUPS,
        groups,
    }
}

export const updateGroup = (groupId, data) => {
    return {
        type: UPDATE_GROUPS,
        groupId,
        data,
    }
}

export const removeGroup = (delGroupId) => {
    return {
        type: REMOVE_GROUP,
        delGroupId,
    }
}

export const setActiveGroups = (activeGroups) => {
    return {
        type: SET_ACTIVE_GROUPS,
        activeGroups,
    }
}

export const updateActiveGroup = (activeGroupId, activeData) => {
    return {
        type: UPDATE_ACTIVE_GROUP,
        activeGroupId,
        activeData,
    }
}

export const removeActiveGroup = (removeGroupId) => {
    return {
        type: REMOVE_ACTIVE_GROUP,
        removeGroupId,
    }
}

export const addUserToGroup = (token, userId, groupId) => async dispatch => {
    try {
        const addResponse = await ApiService.addUserToGroup(token, userId, groupId)
        dispatch(updateGroup(groupId, addResponse.data.group))
        dispatch(updateUser(userId, addResponse.data.user))
        if (addResponse.data.group.active) { dispatch(updateActiveGroup(groupId, addResponse.data.group)) }
        if (addResponse.data.user.active) { dispatch(updateActiveUser(userId, addResponse.data.user)) }

    } catch(error) {
        console.warn(error)
    }
}

export const removeUserFromGroup = (token, userId, groupId) => async dispatch => {
    try {
        const removeResponse = await ApiService.removeUserFromGroup(token, userId, groupId)
        dispatch(updateGroup(groupId, removeResponse.data.group))
        dispatch(updateUser(userId, removeResponse.data.user))
        if (removeResponse.data.group.active) { dispatch(updateActiveGroup(groupId, removeResponse.data.group)) }
        if (removeResponse.data.user.active) { dispatch(updateActiveUser(userId, removeResponse.data.user)) }

    } catch(error) {
        console.warn(error)
    }
}

export const addExperimentToGroup = (token, experimentId, groupId) => async dispatch => {
    try {
        const addResponse = await ApiService.addExperimentToGroup(token, experimentId, groupId)
        dispatch(updateGroup(groupId, addResponse.data.group))
        dispatch(updateExperiment(experimentId, addResponse.data.experiment))
        if (addResponse.data.group.active) { dispatch(updateActiveGroup(groupId, addResponse.data.group)) }
        if (addResponse.data.experiment.active) { dispatch(updateActiveExperiment(experimentId, addResponse.data.experiment)) }

    } catch(error) {
        console.warn(error)
    }
}

export const removeExperimentFromGroup = (token, experimentId, groupId) => async dispatch => {
    try {
        const removeResponse = await ApiService.removeExperimentFromGroup(token, experimentId, groupId)
        dispatch(updateGroup(groupId, removeResponse.data.group))
        dispatch(updateExperiment(experimentId, removeResponse.data.experiment))
        if (removeResponse.data.group.active) { dispatch(updateActiveGroup(groupId, removeResponse.data.group)) }
        if (removeResponse.data.experiment.active) { dispatch(updateActiveExperiment(experimentId, removeResponse.data.experiment)) }


    } catch(error) {
        console.warn(error)
    }
}

export const getAllGroups = (token) => async dispatch => {
    try {
        const getResponse = await ApiService.getGroups(token)
        const groups = {}
        const active = {}

        if (getResponse) {
            getResponse.data.forEach(element => {
                groups[ element.id ] = element
                if (element.active) {
                    active[ element.id ] = element
                }
            })
        }
        dispatch(setGroups(groups))
        dispatch(setActiveGroups(active))

    } catch(error) {
        console.warn(error)
    }
}

export const updateGroupRequest = (token, data) => async dispatch => {
    try {
        const updateResponse = await ApiService.updateGroup(token, data)
        if (updateResponse) {
            dispatch(updateGroup(updateResponse.data.id, updateResponse.data))

            if (data.active || updateResponse.data.active) {
                dispatch(updateActiveGroup(updateResponse.data.id, updateResponse.data))
            } else if ( data.active === false ) {
                dispatch(removeActiveGroup(updateResponse.data.id))
            }
        }
        return updateResponse.data

    } catch(error) {
        console.warn(error)
    }
}

export const createGroupRequest = (token, data) => async dispatch => {
    try {
        const createResponse = await ApiService.createGroup(token, data)
        if (createResponse) {
            dispatch(updateGroup(createResponse.data.id, createResponse.data))
            dispatch(updateActiveGroup(createResponse.data.id, createResponse.data))
        }
        return createResponse.data

    } catch(error) {
        console.warn(error)
    }
}

export const deleteGroupRequest = (token, groupId) => async dispatch => {
    try {
        const deleteResponse = await ApiService.deleteGroup(token, groupId)
        if (deleteResponse) {
            dispatch(removeGroup(groupId))
            dispatch(removeActiveGroup(groupId))
        }
        return deleteResponse.data

    } catch(error) {
        console.warn(error)
    }
}