import React, { Component } from 'react'
import { 
  Typography, 
  Divider, 
  Icon, 
  Table,
  Button,
  Tooltip,
  Spin,
  Popconfirm,
  Checkbox,
  Input,
  Modal,
  Radio,
  Form,
  List,
  Upload,
  Row,
  Col,
  AutoComplete,
 } from 'antd'
import { connect } from 'react-redux'
import { getAllExperiments, updateExperimentRequest, createExperimentRequest, bulkCreateExperimentRequest, getSingleExperimentRequest, deleteExperimentRequest } from '../features/experiments/experimentsActions'
import { getAllConfigurations, createConfigurationRequest } from '../features/configuration/configurationActions'
import { getAllGroups, addExperimentToGroup } from '../features/groups/groupsActions'
import Highlighter from 'react-highlight-words';
import _ from 'lodash'
import { BACKEND_URL, EXPERIMENTS_URL} from '../shared/constants'

const EXPERIMENT_PAGE = 'experiment'
const GROUP_PAGE = 'groups'
const CHANNEL_PAGE = 'channels'

class Experiment extends Component {
    constructor(props) {
        super(props);
        this.columns = [
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            sorter: (a, b) => this.sorterHelper(a.name, b.name),
            ...this.getColumnSearchProps('name'),
          },
          {
            title: 'Status',
            dataIndex: 'upload_status',
            key: 'status',
            fixed: 'left',
            render: (text, record) => { 
              switch (record.upload_status) {
                case 'created': return <Tooltip title="Created"><Icon type="info-circle" theme="twoTone"  twoToneColor="#ffa940"/></Tooltip>
                case 'in_progress': return <Tooltip title="In Progress"><Icon type="clock-circle" theme="twoTone" twoToneColor="#fadb14"/></Tooltip>
                case 'complete': return <Tooltip title="Complete"><Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a"/></Tooltip>
                case 'error': return  <Tooltip title="Error"><Icon type="stop" theme="twoTone" twoToneColor="#cf1322" /></Tooltip>
                case 'deleting': return  <Tooltip title="Deleting"><Icon type="delete" theme="twoTone" twoToneColor="#531dab" /></Tooltip>
                default: return 'Unknown'
              }
            },
            filters: [
              {
                text: 'Created',
                value: 'created',
              },
              {
                text: 'In Progress',
                value: 'in_progress',
              },
              {
                text: 'Complete',
                value: 'complete',
              },
              {
                text: 'Error',
                value: 'error',
              },
              {
                text: 'Deleting',
                value: 'deleting',
              },
            ],
            onFilter: (value, record) => {
            return record.upload_status === value},
          },
          {
            title: 'Principal Investigator',
            dataIndex: 'principal_investigator_display',
            key: 'principal_investigator',
          },
          { title: 'Treated By', dataIndex: 'treated_by_display', key: 'treated_by' },
          { title: 'Protocol', dataIndex: 'protocol_display', key: 'protocol' },
          { title: 'Instrument', dataIndex: 'instrument_display', key: 'instrument' },
          { title: 'Submitted By', dataIndex: 'submitted_by_display', key: 'submitted_by' },
          { title: 'Processed By', dataIndex: 'processed_by_display', key: 'processed_by' },
          { title: 'Cell Line', dataIndex: 'cell_line_display', key: 'cell_line' },
          {
            title: 'Active',
            key: 'active',
            fixed: 'right',
            render: (text, record) =>  <Popconfirm title='Toggle active?' onConfirm={() =>this.onCheckChange(record)}><Checkbox checked={record.active}/></Popconfirm>,
            defaultFilteredValue: [true],
            filters: [
              {
                text: 'Active',
                value: true,
              },
              {
                text: 'Inactive',
                value: false,
              },
            ],
            onFilter: (value, record) => {
            return record.active === value},
      },
          {
            title: 'Edit',
            key: 'edit',
            fixed: 'right',
            width: 100,
            render: (text, record) => <Button shape='circle' size='small' icon='edit' onClick={()=>this.showEditModal(record)} />,
          },
          {
            title: 'Export',
            key: 'Export',
            fixed: 'right',
            width: 100,
            render: (text, record) => <Button shape='circle' size='small' icon='export' onClick={()=> window.open(`${BACKEND_URL}${EXPERIMENTS_URL}${record.id}/export?token=${this.props.token}`, "_blank")} />,
          },
          {
            title: 'Delete',
            key: 'delete',
            fixed: 'right',
            width: 100,
            render: (text, record) => <Popconfirm title='Are you sure?' onConfirm={() => this.props.deleteExperimentRequest(this.props.token, record.id)}>
              <Button style={{marginLeft: 10}}icon='close' size='small' type='danger' shape='circle'></Button>
              </Popconfirm>
          },
        ]
        this.state = { 
          loading: true,
          createVisible: false,
          channelClicked: false,
          createPage: EXPERIMENT_PAGE,
          createFileData: null,
          createFileName: null,
          createChannels: null,
          groupList: [],
          create: {
            investigator: null,
            instrument: null,
            protocol: null,
            cellLine: null,
            treatedBy: null,
            processedBy: null,
            uploadData: null,
            submittedBy: null,
          },
          channelData: {
            tmt_label: [],
            treatment: [],
            compounds: [],
            time: [],
            concentration: [],
            notes: [],
          },
          editVisible: false,
          bulkVisible: false,
          bulkDataExperiment: null,
          bulkDataChannel: [],
          bulkDataOutput: [],
          bulkChannelNames: [],
          bulkOutputNames: [],
          bulkLoading: false,
          editExperiment: null,
         }
    }

    getColumnSearchProps = dataIndex => ({
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
              <Input
                ref={node => {
                  this.searchInput = node;
                }}
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
              />
              <Button
                type="primary"
                onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                icon="search"
                size="small"
                style={{ width: 90, marginRight: 8 }}
              >
                Search
              </Button>
              <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </div>
          ),
          
          filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
          ),
          
          onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()) : '',
          
              onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => this.searchInput.select());
            }
          },
          
          render: text => (
          (this.state.searchedColumn === dataIndex && text)?
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
            : text
          ),
    });
  
    handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      this.setState({ 
        searchText: selectedKeys[0],
        searchedColumn: dataIndex,
        });
    };
  
    handleReset = clearFilters => {
      clearFilters();
      this.setState({ searchText: '' });
    };

    onCheckChange(record) {
      this.props.updateExperimentRequest(this.props.token, {id: record.id, active: ! record.active})
    }

    componentDidMount() {
      let promises = [
        this.props.getAllExperiments(this.props.token),
        this.props.getAllGroups(this.props.token),
        this.props.getAllConfigurations(this.props.token),
      ]
      Promise.all(promises).then( value => this.setState({loading: false}))
  }
  sorterHelper = (a, b) => {
    if (a) {
      return  b ? a.localeCompare(b) : -1
    } else if (b) {
      return a ? b.localeCompare(a) : 1
    } else {
      return -1
    }
  } 

  showEditModal = (record) => {
    const { token, getSingleExperimentRequest } = this.props
    this.setState({loading: true}, () => {
      getSingleExperimentRequest(token, record.id).then(resp => {
        this.setState({
          loading: false,
          editVisible: true,
          editExperiment: record,
          channelClicked: false,
          createPage: EXPERIMENT_PAGE,
          createFileData: null,
          createFileName: null,
          createChannels: _.map(resp.channels, (channel => {
            return `Abundance.F00.${channel.tmt.name}.Sample.${channel.compound_treatment.treatment.name}`
          })),
          groupList: _.map(resp.groups, 'id'),
          create: {
            name: resp.name,
            investigator: resp.principal_investigator_display,
            instrument: resp.instrument_display,
            protocol: resp.protocol_display,
            cellLine: resp.cell_line_display,
            treatedBy: resp.treated_by_display,
            processedBy: resp.processed_by_display,
            uploadData: null,
            submittedBy: resp.submitted_by_display,
          },    

          channelData: {
            compounds: _.map(
              resp.channels, 
              (channel) => channel.compound_treatment.compound.name
            ),
            time: _.map(
              resp.channels, 
              'time'
            ),
            concentration: _.map(
              resp.channels, 
              'drug_concentration'
            ),
            notes: _.map(
              resp.channels, 
              'notes'
            ),
            tmt_label: _.map(
              resp.channels, 
              (channel) => channel.tmt.name        
            ),
            treatment: _.map(
              resp.channels, 
              (channel) => channel.compound_treatment.treatment.name        
            ),
          },
        });
      })
    })
  };

  getCurrentState = () => {
    const mostRecentData = this.persistCreateFormToState()
    let { create, channelData, groupList, createFileData, createFileName, createPage } = this.state
    if ( createPage === EXPERIMENT_PAGE ) {
      create = mostRecentData
    } else if ( createPage === CHANNEL_PAGE ) {
      channelData = mostRecentData
    }

    return {
      create,
      channelData,
      groupList,
      createFileData,
      createFileName,
      createPage,
    }
  }

  showCreateModal = (e) => {
    this.handleCreateCancel(e, true)
  };

  handleCreateOk = (e) => {  
    const { updateExperimentRequest, addExperimentToGroup, createExperimentRequest, token, createConfigurationRequest, configuration } = this.props
    let { create, channelData, groupList, createFileData, createFileName } = this.getCurrentState()

    const { name, treatedBy, processedBy, cellLine, investigator, instrument, submittedBy, protocol } = create
    const { compounds, time, concentration, notes, tmt_label, treatment, } = channelData
    const channel = []

    const { editVisible, editExperiment } = this.state

    this.setState({loading: true}, () => {

      _.range(0, compounds.length).forEach(index => {
          channel.push({
            tmt_label: tmt_label[index],
            treatment: treatment[index],
            compound: compounds[index],
            time: time[index],
            drug_concentration: concentration[index],
            notes: notes[index],
          })
      })

      let promises = []

      let configData = {
        "treated_by_id": null,
        "processed_by_id": null,
        "cell_line_id": null,
        "principal_investigator_id": null,
        "instrument_id": null,
        "submitted_by_id": null,
        "protocol_id": null,
      }

      let configFields = [
        ['treated_by', treatedBy],
        ['processed_by', processedBy], 
        ['cell_line', cellLine],
        ['principal_investigator', investigator], 
        ['instrument', instrument],
        ['submitted_by', submittedBy], 
        ['protocol', protocol]
      ]
      configFields.forEach(item => {

          const configObj = _.find(
            configuration.all,
            obj => obj.config_type === item[0] && obj.value === item[1]
          )
            if (configObj) {
              configData[`${item[0]}_id`] = configObj.id
            } else {
              promises.push(createConfigurationRequest(token, {value: item[1], config_type: item[0]}))
            }
        })

      let finalCreateData = {
        name,
        channel,
        "upload_file": createFileData,
        "upload_name": createFileName,
      }

      Promise.all(promises).then( allResp => {
        allResp.forEach(eachResp => {
          configData[`${eachResp.config_type}_id`] = eachResp.id
        })

        if (editVisible) {
          finalCreateData['id'] = editExperiment.id
          finalCreateData['group'] = groupList

          delete finalCreateData['upload_file']
          delete finalCreateData['upload_name']

          updateExperimentRequest(token, _.merge(finalCreateData, configData)).then(()=>{        
            this.setState({loading: false})
        })

        } else {
          createExperimentRequest(token, _.merge(finalCreateData, configData)).then(experimentResponse => {
            this.setState({loading: false})
            try {
              groupList.forEach(groupId => {
                addExperimentToGroup(token, experimentResponse['id'], groupId)
              })
            } catch (e) {
              console.log(e)
          }
          })
        }
        this.handleCreateCancel(e)
      })
    })
  }

  handleCreateCancel = (e, visible=false) => {
    // clear create state
    this.setState({
      editVisible: false,
      editExperiment: null,
      createVisible: visible,
      channelClicked: false,
      createPage: EXPERIMENT_PAGE,
      createFileData: null,
      createFileName: null,
      createChannels: null,
      groupList: [],
      create: {
        name: null,
        investigator: null,
        instrument: null,
        protocol: null,
        cellLine: null,
        treatedBy: null,
        processedBy: null,
        uploadData: null,
        submittedBy: null,
      },    
      channelData: {
        compounds: [],
        time: [],
        concentration: [],
        notes: [],
        tmt_label: [],
        treatment: [],
      },
    });
  };

  onCreateChange = (e) => {
    this.persistCreateFormToState()
    if (! this.state.editVisible) { this.props.form.validateFields() }

    if (e.target.value === CHANNEL_PAGE) {
      this.setState({channelClicked: true})
    }

    this.setState({createPage: e.target.value})
  }

  persistCreateFormToState() {
    const { getFieldValue } = this.props.form
    const { createPage, createChannels, editVisible, } = this.state

    const prefix = editVisible ? 'edit' : 'create'

    switch (createPage) {
      case EXPERIMENT_PAGE:
        const create = {
          name: getFieldValue(`${prefix}-name`),
          investigator: getFieldValue(`${prefix}-principal_investigator`),
          instrument: getFieldValue(`${prefix}-instrument`),
          protocol: getFieldValue(`${prefix}-protocol`),
          cellLine: getFieldValue(`${prefix}-cell_line`),
          treatedBy: getFieldValue(`${prefix}-treated_by`),
          processedBy: getFieldValue(`${prefix}-processed_by`),
          submittedBy: getFieldValue(`${prefix}-submitted_by`),
          uploadData: getFieldValue('dragger'),
        }
        this.setState({
          create
        })
        return create

      case CHANNEL_PAGE:
        const channelFormTypes = [
          'time', 'notes', 'compounds', 'concentration','tmt_label', 'treatment']

        const channelData = {
          compounds: [],
          time: [],
          concentration: [],
          notes: [],
          tmt_label: [],
          treatment: [],
        }

        channelFormTypes.forEach( formRow => {
          _.range(createChannels.length).forEach(channelIndex => {
            let formDataEntry = getFieldValue(`${formRow}-${channelIndex}`)
            channelData[formRow].push(formDataEntry)
          })
        })

        this.setState({
          channelData
        })
        return channelData

      case GROUP_PAGE:
        break
      
      default:
        console.log('pass')
    }
  }

  createGroupListCheckboxEvent = (e, item) => {
    const { groupList } = this.state

    if (e.target.checked) {
      groupList.push(item.id)
    } else {
      _.remove(groupList, (n) => n === item.id)

    }
    this.setState({
      groupList
    })
  }

  normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  showCreatePage() {
    const { createPage, create, channelData, editVisible } = this.state 
    const { getFieldDecorator} = this.props.form;

    switch (createPage) {
      case EXPERIMENT_PAGE:
        return <><Form.Item>
        {getFieldDecorator('dragger', {
          initialValue: create.uploadData ? create.uploadData : null,
          valuePropName: 'fileList',
          getValueFromEvent: this.normFile,
        })(
          <Upload.Dragger     
            style={editVisible ? {display: 'none'} : {}}
            accept=".txt, .csv"
            onRemove={file => {
              this.setState({
                createFileData: null,
                createFileName: null,
                createChannels: null,
              })
            }}
            beforeUpload={file => {
              const reader = new FileReader();
      
              reader.onload = e => {
                  const createChannels = e.target.result.split('\n',2)[0].split('"').filter( s => {
                      return s.includes('Abundance')
                    })
                  const createFileData = btoa(e.target.result)
                  const createFileName = file.name
                  const { create } = this.state
                  create.name =  `${createFileName.split('_')[0]}_${createFileName.split('_')[1]}`
                  this.setState({
                    createFileData,
                    createFileName,
                    createChannels,
                    create,
                  })
              };
              reader.readAsText(file);
      
              return false;
          }}
      >
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">Support for a single upload (for now).</p>
          </Upload.Dragger>,
        )}
        </Form.Item>
        {this.createFormItem('experiment name', 'name', editVisible ? 'edit' : 'create', 'name')}
        {this.createFormItem('investigator', 'principal_investigator', editVisible ? 'edit' :'create')}
        {this.createFormItem('instrument', 'instrument', editVisible ? 'edit' :'create')}
        {this.createFormItem('protocol', 'protocol', editVisible ? 'edit' :'create')}
        {this.createFormItem('cell line', 'cell_line', editVisible ? 'edit' :'create', 'cellLine')}
        {this.createFormItem('treated by', 'treated_by', editVisible ? 'edit' :'create', 'treatedBy')}
        {this.createFormItem('processed by', 'processed_by', editVisible ? 'edit' :'create', 'processedBy')}
        {this.createFormItem('submitted by', 'submitted_by', editVisible ? 'edit' :'create', 'submittedBy')}
        </>
        case CHANNEL_PAGE:
          const children = [<Row gutter={8} key={'title'}>
              <Col className='uploadLabel' span={4}>
                TMT Label
              </Col>
              <Col className='uploadLabel' span={4}>
                Treatment
              </Col>
              <Col className='uploadLabel' span={4}>
                Compound
              </Col>
              <Col className='uploadLabel' span={4}>
                Time [min]
              </Col>
              <Col className='uploadLabel' span={4}>
                Concentration [µM]
              </Col>
              <Col className='uploadLabel' span={4}>
                Notes
              </Col>
          </Row>];
          this.state.createChannels.forEach((value, i) => {
            children.push(
              <Row gutter={2} key={i}>
                <Col span={4}>
                  <Form.Item>
                    {getFieldDecorator(`tmt_label-${i}`, {
                      rules: [
                        {
                          required: true,
                        },
                      ],
                      initialValue: value.split('.')[2],
                    })(<Input disabled={true}/>)}
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item>
                    {getFieldDecorator(`treatment-${i}`, {
                      rules: [
                        {
                          required: true,
                        },
                      ],
                      initialValue: value.split('Sample.')[1],
                    })(<Input disabled={true} />)}
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item>
                    {getFieldDecorator(`compounds-${i}`, {
                      rules: [
                        {
                          required: true,
                          message: 'Input compound!',
                        },
                      ],
                      initialValue: channelData.compounds && channelData.compounds[i] ? channelData.compounds[i] : value.split('Sample.')[1],
                    })(<Input disabled={value.split('.', 5)[4] === 'DMSO'} placeholder="Required" />)}
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item>
                    {getFieldDecorator(`time-${i}`, {
                      rules: [
                        {
                          required: true,
                          message: 'Input time!',
                        },
                      ],
                      initialValue: channelData.time ? channelData.time[i] : null,
                    })(<Input placeholder="Enter mins" />)}
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item>
                    {getFieldDecorator(`concentration-${i}`, {
                      rules: [
                        {
                          required: true,
                          message: 'Input concentration!',
                        },
                      ],
                      initialValue: channelData.concentration ? channelData.concentration[i] : null,
                    })(<Input placeholder="Enter number as number with up to four digits after decimal point" />)}
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item>
                    {getFieldDecorator(`notes-${i}`, {
                      rules: [
                        {
                          required: false,
                        },
                      ],
                      initialValue: channelData.notes ? channelData.notes[i] : null,
                    })(<Input placeholder="Optional" />)}
                  </Form.Item>
                </Col>
              </Row>,
            );
          })
                
          return <>
            {children}
          </>
        
          case GROUP_PAGE:
            return <>        
            <List
              bordered={true}
              pagination={true}
              dataSource={_.map(this.props.groups.active, (data => data))}
              renderItem={item => (
                <List.Item key={item.id}> 
                  <List.Item.Meta
                  avatar={<Checkbox checked={this.state.groupList.includes(item.id)} onChange={(e) => this.createGroupListCheckboxEvent(e, item)}/>}
                  title={item.name}
                  />
                </List.Item>
              )}
            >
            </List>
            </>
          
          default:
            return <p>Error: Frontend Issue, Contact Admin</p>

    }
  }

    filterConfig(filterType) {
      const unfilteredValues = _.map(_.filter(
        this.props.configuration.active, 
        config_item => config_item.config_type === filterType
        ), data => data.value
      )
      return unfilteredValues.sort(this.sorterHelper)
    }

    createFormItem(itemLabel, itemType = null, labelType = 'edit', stateName=null) {
      const { getFieldDecorator } = this.props.form
      const { create } = this.state
      const configObjectName = itemType ? itemType : itemLabel

      const formItemLayout ={
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      }

      return <Form.Item label={_.startCase(itemLabel)} {...formItemLayout} >{getFieldDecorator(`${labelType}-${configObjectName}`, {
        rules: [
          {
            required: true,
            message: `Must have an ${itemLabel}!`
          },
        ],
        initialValue: create[stateName ? stateName : itemLabel],
      })(    
      <AutoComplete
        dataSource={this.filterConfig(configObjectName)}
        placeholder={`Enter ${itemLabel}`}
        filterOption={(inputValue, option) =>{
          return option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
        }
      />
      )}
      </Form.Item>
    }      
    
    hasErrors(fieldsError) {
      return Object.keys(fieldsError).some(field => fieldsError[field]);
    }
    
    showBulkPage() {
      const { getFieldDecorator } = this.props.form
      const { bulkLoading } = this.state

      if (bulkLoading) {
        return <Spin />
      }
      
      return <>
      <Form.Item label='Experiment CSV'>
        {getFieldDecorator('bulkDraggerExperiment', {
          valuePropName: 'fileList',
          getValueFromEvent: this.normFile,
        })(
          <Upload.Dragger     
            accept=".csv"
            multiple={false}
            onChange={(e) => {
              if (e.fileList.length > 1) {
                e.fileList.shift()
              }
            }}
            beforeUpload={file => {
              return false;
          }}
      >
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">Click or drag one file to this area to upload</p>
            <p className="ant-upload-hint">Add one CSV that contains mappings for all experiment fields.</p>
          </Upload.Dragger>,
        )}
        </Form.Item>
        <Form.Item label='Channel CSVs'>
        {getFieldDecorator('bulkDraggerChannel', {
          valuePropName: 'fileList',
          getValueFromEvent: this.normFile,
        })(
          <Upload.Dragger     
            accept=".csv"
            multiple={true}
            beforeUpload={file => {
              return false;
          }}
      >
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">Click or drag multiple files to this area to upload</p>
            <p className="ant-upload-hint">Add many CSVs, one per experiment, that contain metadata for each channel in each experiment.</p>
          </Upload.Dragger>,
        )}
        </Form.Item>
        <Form.Item label='MS Output files'>
        {getFieldDecorator('bulkDraggerOutput', {
          valuePropName: 'fileList',
          getValueFromEvent: this.normFile,
        })(
          <Upload.Dragger     
            accept=".txt, .csv"
            multiple={true}
            beforeUpload={file => {
              return false;
          }}
      >
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">Click or drag multiple files to this area to upload</p>
            <p className="ant-upload-hint">Add many CSVs or text files, one per experiment, that contain mass spectronomy output for each experiment.</p>          
        </Upload.Dragger>,
        )}
        </Form.Item>
      </>
    }

    handleBulkCancel() {
      this.setState({bulkVisible: false, bulkDataOutput: [], bulkDataExperiment: null, bulkDataChannel: [], bulkChannelNames:[], bulkOutputNames: []})    
    }

    handleBulkOk = () => {
      this.setState({bulkLoading: true})
      const { bulkCreateExperimentRequest, form, token } = this.props
      const { getFieldsValue } = form
      const experimentReader = new FileReader();
 
      experimentReader.onload = e => {
        const bulkDataExperiment = btoa(e.target.result)
        this.setState({
          bulkDataExperiment 
        })
      };
      const channelLoad = e => {
        const { bulkDataChannel } = this.state
        const newBulkDataChannel = btoa(e.target.result)
        bulkDataChannel.push(newBulkDataChannel)
        this.setState({
          bulkDataChannel 
        })
      };
      const outputLoad = e => {
        const { bulkDataOutput } = this.state
        const newBulkDataOutput = btoa(e.target.result)
        bulkDataOutput.push(newBulkDataOutput)
        this.setState({
          bulkDataOutput 
        })
      };
      getFieldsValue(['bulkDraggerExperiment']).bulkDraggerExperiment.forEach(inputFile => {
        experimentReader.readAsText(inputFile.originFileObj);
      })
      getFieldsValue(['bulkDraggerChannel']).bulkDraggerChannel.forEach(inputFile => {
        const channelReader = new FileReader();
        channelReader.onload = channelLoad
        channelReader.onloadend = e => {
          const { bulkChannelNames } = this.state
          bulkChannelNames.push(inputFile.name)
          this.setState({bulkChannelNames})
        }
        channelReader.readAsText(inputFile.originFileObj);
      })
      getFieldsValue(['bulkDraggerOutput']).bulkDraggerOutput.forEach(inputFile => {
        const outputReader = new FileReader();
        outputReader.onload = outputLoad
        outputReader.onloadend = e => {
          const { bulkOutputNames } = this.state
          bulkOutputNames.push(inputFile.name)
          this.setState({bulkOutputNames})
        }
        outputReader.readAsText(inputFile.originFileObj);
      })

      setTimeout(() => {
        const { bulkDataExperiment, bulkDataOutput, bulkDataChannel, bulkChannelNames, bulkOutputNames } = this.state
        bulkCreateExperimentRequest(token, {
          experiment_data: bulkDataExperiment,
          channel_data: bulkDataChannel,
          output_data: bulkDataOutput,
          channel_names: bulkChannelNames,
          output_names: bulkOutputNames
        }).then( resp => {
          this.setState({bulkLoading: false, bulkVisible: false, bulkDataOutput: [], bulkDataExperiment: null, bulkDataChannel: [], bulkChannelNames:[], bulkOutputNames: []})
        })},
        500 * getFieldsValue(['bulkDraggerOutput']).bulkDraggerOutput.length)
    }
    
    render() {
        const {Title} = Typography
        const { createChannels, channelData, createVisible, loading, editVisible, editExperiment,  createFileData, create, createPage, channelClicked, bulkVisible } = this.state;
        const { getFieldsError, getFieldsValue, isFieldTouched } = this.props.form

        let disableCreate = true

        if (createPage === EXPERIMENT_PAGE) { // to future me: really sorry about this, it is quite horrifying yes
          const prefix = editVisible ? 'edit' : 'create'
          let validFields = [
            {investigator: `${prefix}-principal_investigator`}, 
            {protocol: `${prefix}-protocol`}, 
            {submittedBy: `${prefix}-submitted_by`}, 
            {treatedBy: `${prefix}-treated_by`},
            {instrument: `${prefix}-instrument`}, 
            {cellLine: `${prefix}-cell_line`}, 
            {name: `${prefix}-name`}, 
            {processedBy: `${prefix}-processed_by`},
          ]

          if (! editVisible ) {
            validFields['upload'] = 'dragger'
          }

          const allVals = getFieldsValue(_.map(validFields, obj => Object.values(obj)[0]))

          disableCreate = (
            _.some(channelData.compounds, (element) => {return _.isNil(element) || element === ''})
            ||  _.some(channelData.time, (element) => {return _.isNil(element) || element === '' || ! /^\d+$/.test(element)})
            ||  _.some(channelData.concentration, (element) => {return _.isNil(element) || element === '' || ! /^\d+\.?\d{0,4}$/.test(element)}))
            || (
              _.some(Object.values(allVals), _.isNull) 
              || this.hasErrors(getFieldsError())
              || 
                (channelClicked && _.some(validFields, (obj) => {
                  return _.isEmpty(create[Object.keys(obj)[0]]) 
                    && (! isFieldTouched([Object.values(obj)[0]]) 
                    || _.isNil(getFieldsValue([Object.values(obj)[0]])))
              }))
            )

        } else {
          let expErrors = (! (Boolean(create.cellLine) && Boolean(create.instrument) && Boolean(create.investigator) && Boolean(create.protocol)
          && Boolean(create.submittedBy) && Boolean(create.treatedBy) && Boolean(create.name) && Boolean(create.processedBy) 
          && (Boolean(create.uploadData) || editVisible)
          )) || this.hasErrors(getFieldsError())

          if ( createPage === CHANNEL_PAGE ) {
            disableCreate = expErrors ||
            _.some(_.range(createChannels.length), (i) => {
              return (channelData.time && 
                (  
                  (
                    ( _.isNil(channelData.time[i]) || channelData.time[i] === '' || ! /^\d+$/.test(channelData.time[i]) ) 
                    && ! isFieldTouched(`time-${i}`)
                  )
                  || (isFieldTouched(`time-${i}`) && ! /^\d+$/.test(Object.values(getFieldsValue([`time-${i}`]))[0]))
                ))
                || (channelData.compounds && (  
                  (_.isNil(channelData.compounds[i]) || channelData.compounds[i] === '') 
                  && ! isFieldTouched(`compounds-${i}`)))
                || (channelData.concentration &&  
                  (  
                    (
                      ( _.isNil(channelData.concentration[i]) || channelData.concentration[i] === '' || ! /^\d+\.?\d{0,4}$/.test(channelData.concentration[i]) ) 
                      && ! isFieldTouched(`concentration-${i}`)
                    )
                    || (isFieldTouched(`concentration-${i}`) && ! /^\d+\.?\d{0,4}$/.test(Object.values(getFieldsValue([`concentration-${i}`]))[0]))
                  ))            
                })

          } else {
            disableCreate = expErrors ||  
            _.some(channelData.compounds, (element) => {return _.isNil(element) || element === ''})
            ||  _.some(channelData.time, (element) => {return _.isNil(element) || element === '' || ! /^\d+$/.test(element)})
            ||  _.some(channelData.concentration, (element) => {return _.isNil(element) || element === '' || ! /^\d+\.?\d{0,4}$/.test(element)})
          }
        }
        
        return <>
          <Typography>
            <Title level={3}><Icon type='dot-chart' /> Experiments <Tooltip title='Single upload'><Button shape="circle" size='small' icon='plus' onClick={this.showCreateModal} 
              style={{ marginBottom: 16 }} /></Tooltip><Tooltip title='Bulk upload'><Button shape="circle" size='small' icon='block' onClick={()=>{this.setState({bulkVisible: true})}} 
              style={{ marginBottom: 16, marginLeft: 5 }} /></Tooltip>
            </Title>
          </Typography>
          <Divider />
          {loading
          ? <Spin />
          : <Table columns={this.columns} rowKey='id' dataSource={_.map(this.props.experiments.all, (data => data))} scroll={{ x: 1850 }} />}
          <Modal
          width={775}
          title="Create Experiment"
          visible={createVisible}
          onOk={this.handleCreateOk}
          onCancel={this.handleCreateCancel}
          destroyOnClose={true}
          okButtonProps={{ disabled: disableCreate || ! channelClicked, icon:'upload'}}
          cancelButtonProps={{icon: 'close'}}
          okText={'Submit'}
          >
            <Radio.Group className='createRadioGroup' onChange={this.onCreateChange} defaultValue={EXPERIMENT_PAGE}>
              <Radio.Button value={EXPERIMENT_PAGE}>Experiment</Radio.Button>
              <Radio.Button value={CHANNEL_PAGE} disabled={! Boolean(createFileData)}>Channels</Radio.Button>
              <Radio.Button value={GROUP_PAGE}>Groups</Radio.Button>
            </Radio.Group>
            <Form>
            {this.showCreatePage()}
            </Form>
        </Modal>
        <Modal
          width={775}
          title={`Edit ${editExperiment ? editExperiment.name : 'experiment'}`}
          visible={editVisible}
          onOk={this.handleCreateOk}
          onCancel={this.handleCreateCancel}
          destroyOnClose={true}
          okButtonProps={{ disabled: disableCreate, icon:'upload'}}          
          okText={'Submit'}
          >
            <Radio.Group className='createRadioGroup' onChange={this.onCreateChange} defaultValue={EXPERIMENT_PAGE}>
              <Radio.Button value={EXPERIMENT_PAGE}>Experiment</Radio.Button>
              <Radio.Button value={CHANNEL_PAGE}>Channels</Radio.Button>
              <Radio.Button value={GROUP_PAGE}>Groups</Radio.Button>
            </Radio.Group>
            <Form>
              {this.showCreatePage()}
            </Form>
        </Modal>
        <Modal
          width={775}
          title={'Bulk Upload'}
          visible={bulkVisible}
          onOk={() => this.handleBulkOk()}
          onCancel={() => this.handleBulkCancel()}
          destroyOnClose={true}
          okButtonProps={{ disabled: ! (getFieldsValue(['bulkDraggerOutput']).bulkDraggerOutput && getFieldsValue(['bulkDraggerChannel']).bulkDraggerChannel && getFieldsValue(['bulkDraggerExperiment']).bulkDraggerExperiment), icon:'upload'}}          
          okText={'Submit'}
          >
              <Button icon='download' 
                onClick={()=> {window.open(`${BACKEND_URL}/experiments_template?token=${this.props.token}`, "_blank")}}>Download Templates
              </Button>
            <Form>
              {this.showBulkPage()}
            </Form>
        </Modal>
        </>
    }
}

const mapStateToProps = ({ experiments, groups, configuration }) => {
  return { 
      experiments, 
      groups,
      configuration,
  }
}

export default connect (mapStateToProps, {
  getAllExperiments,
  getAllGroups,
  updateExperimentRequest,
  createExperimentRequest,
  getAllConfigurations,
  createConfigurationRequest,
  addExperimentToGroup,
  bulkCreateExperimentRequest,
  getSingleExperimentRequest,
  deleteExperimentRequest,
}) (Form.create({ name: 'experiment_form' })(Experiment))