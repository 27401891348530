import React, { Component } from 'react';
import _, { isNil } from 'lodash'
import Plot from 'react-plotly.js';
import { Spin } from 'antd'
import { connect } from 'react-redux'
import { getResultDataRequest, getExperimentResultDataRequest } from   '../features/experiments/experimentsActions'
import { setClicked, setLoadingAsync } from   '../features/results/resultsActions'

class Volcano extends Component {

    render() {
      const { experiments, results, hits, token, getResultDataRequest, setClicked, setLoadingAsync, getExperimentResultDataRequest } = this.props
      const { searchedExperiment } = experiments
      const experimentTitle = searchedExperiment.meta.name
      const filterTitle = searchedExperiment.meta.filter_name
      const filterLabel = searchedExperiment.meta.filter_label
      const experimentData = ! filterLabel && results.volcanoUnique ? _.filter(searchedExperiment.data, obj => {
        return obj.unique_peptides >= results.volcanoUnique
      }) : searchedExperiment.data
      experimentData.forEach(obj => {
        obj['gene_symbol'] = obj['gene_symbol'].split(';')[0]
      })

      const { volcanoType, volcanoFC, volcanoP, volcanoX, volcanoYMin, volcanoYMax } = results
      const useDefault = _.isNil(volcanoFC) || _.isNil(volcanoP) || isNil(volcanoType)
      
      const defaultPiUp = ! _.isEmpty(experimentData) ? (useDefault ? experimentData[0].default_up : Math.log2(volcanoFC) * volcanoP) : 0
      const defaultPiDown = ! _.isEmpty(experimentData) ? (useDefault ? experimentData[0].default_down : Math.log2(volcanoFC) * volcanoP * -1) : 0

      const logPValues =  _.map(experimentData, 'neg_log10_p_value')
      const logFCValues =  _.map(experimentData, 'log_fc')

      const colors = filterLabel ? _.map(experimentData, () => 'blue') : _.map(hits, (item => {
        return item ? 'red' : 'blue'
      }))

      const labels = filterLabel ? _.map(experimentData, () => '') : _.map(hits, ((item, index) => {
        return item && experimentData[index] ? experimentData[index].gene_symbol : ''
      }))

      const minX = _.min(logPValues) - 0.25
      const maxXDefault = _.max(logPValues) + 0.25

      const minY = _.min(logFCValues) - 0.25
      const maxY = _.max(logFCValues) + 0.25
      
      const maxX = 0
      const xList = volcanoType === 'square' ? _.range(minX, 0.5, 0.1) : _.range(minX, maxX, 0.1)
      const yList = _.range(minY, maxY, 0.1)

      const yUpList = _.map(xList, x => volcanoType === 'square' ? Math.log2(volcanoFC) : -defaultPiUp / x)
      const yDownList = _.map(xList, x => volcanoType === 'square' ? -1 * Math.log2(volcanoFC) : -defaultPiDown / x)

      const xZoom = _.isNil(volcanoX) ? maxXDefault : volcanoX

      const yZoomMin = _.isNil(volcanoYMin) ? minY : volcanoYMin
      const yZoomMax = _.isNil(volcanoYMax) ? maxY : volcanoYMax
      
      const squareFinal = ! filterLabel && volcanoType === 'square' ? {
        type: 'line', 
        mode: 'lines',
        x: _.map(yList, y => volcanoP), 
        y: yList,            
        line: {
          shape: 'spline', 
          dash: 'dashdot', 
          color:'gray',
          width: 2,
        },
        connectgaps: true,
        hoverinfo: 'none'
    } : {}
      if (_.isNil(experimentData.length)) {
        return <Spin style={{marginTop: 10}} />
      }

      const data = [
            {
              name: '',
              type: 'scatter',
              mode: results.showLabel ? 'markers+text' : 'markers',
              text: labels,
              textposition: 'top center',
              x: logPValues,
              y: logFCValues,
              marker: {
                color: colors,
                    opacity: 0.5,
                    size: 6,
                    line: {
                      color: ['black']
                    }
                },
              hovertemplate: `<b>Log<sub>2</sub>FC:</b> %{y}<br />
<b>-Log<sub>10</sub>P-Value:</b> %{x}<br />
<b>Pi:</b> %{customdata.pi}<br />
<b>Treatment:</b> %{customdata.treatment_name}<br />
<b>Compound:</b> %{customdata.compound_name}<br />
<b>Accession:</b> %{customdata.accession_name}<br />
<b>Gene:</b> %{customdata.gene_symbol}<br />
<b>Unique Peptides:</b> %{customdata.unique_peptides}<br />
${searchedExperiment.meta.filter_label ? '<b>Experiment:</b> %{customdata.experiment_name}<br />' : ''}
<b>Default Hit:</b> %{customdata.default_hit}`,
              customdata: experimentData,
              hoverlabel: {
                bgcolor:"white", 
                font_size: 16, 
                font_family:"Rockwell"
                }
              },
            filterLabel ? {} : {
              type: 'line', 
              mode: 'lines',
              x: xList, 
              y: yUpList,   
              line: {
                shape: 'spline', 
                dash: 'dashdot', 
                color:'gray',
                width: 2,
              },
              connectgaps: true,
              hoverinfo: 'none'
          },
          filterLabel ? {} : {
              type: 'line', 
              mode: 'lines',
              x: xList, 
              y: yDownList,            
              line: {
                shape: 'spline', 
                dash: 'dashdot', 
                color:'gray',
                width: 2,
              },
              connectgaps: true,
              hoverinfo: 'none'
          },
          squareFinal,
          ]
        
        return <>
              <Plot
                config={{
                  displaylogo: false,
                  responsive: true,
                  toImageButtonOptions: {
                    format: 'svg',
                    filename: `${filterLabel ? filterLabel.split(';')[0]+" - ("+filterTitle+")" : filterTitle} - ${experimentTitle}`
                  }
                } }
                onClick={(e) =>  {
                  const data = _.filter(e.points, element => element.data.type === 'scatter')[0]
                  setLoadingAsync(true).then(() => {
                    if ( ! Boolean(filterLabel) ) { 
                      getResultDataRequest(token, data.customdata.accession_id, 'protein').then( (resp) => {
                        if (resp) {
                          setClicked(true)
                        }
                      }
                      )
                    } else {
                      getExperimentResultDataRequest(token, data.customdata.treatment_id, 'treatment', data.customdata.experiment_id, true, `?foo=[${data.customdata.compound_id}]`).then( (resp) => {
                          if (resp) {
                            setClicked(true)
                          }
                        }
                      )
                    }
                  })
                }}
                data={data}
                layout={{ 
                  hovermode: 'closest',
                  title: `${filterLabel ? filterLabel.split(';')[0]+" - ("+filterTitle+")" : filterTitle} - ${experimentTitle}`,
                  autosize: true,
                  showlegend: false,
                  xaxis: {
                    title: `Log<sub>10</sub> P Value`,
                    range:[minX, xZoom],
                  },
                  yaxis: {
                    title: `Log<sub>2</sub> FC Value`,
                    range:[yZoomMin, yZoomMax],

                  }
                }}
                useResizeHandler={true}
                style={{width: "100%"}}
              />
        </>
    }
}

const mapStateToProps = ( { experiments, results }) => {
  return { 
    experiments,
    results,
  }
}
export default connect (mapStateToProps, {
  getResultDataRequest,
  getExperimentResultDataRequest,
  setClicked,
  setLoadingAsync,
}) (Volcano)