import React, { Component } from 'react';
import { Menu, Icon } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import decode from 'jwt-claims'
import dfci_small from '../../assets/dfci_small.png'

import { 
    USERS_URL, 
    EXPERIMENTS_URL, 
    GROUPS_URL, 
    HOME_URL, 
    CONFIGURATION_URL, 
    HEATMAP_URL,
} from '../constants'

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            showAdmin: false, 
            showMain: false, 
         };
      }
    
      toggleAdmin() {
        this.setState({showAdmin: !this.state.showAdmin} )
      }

      toggleMain() {
        this.setState({showMain: !this.state.showMain} )
      }
    render() {
        const { SubMenu } = Menu;
        const admin = this.props.token ? decode(this.props.token).user_claims.admin : false

        return <>
                <Menu style={{ backgroundColor: 'rgb(63, 63, 63)', lineHeight: '64px'}} 
                    theme="dark" 
                    mode="horizontal" 
                    openKeys={['adminMenu', 'mainMenu']}
                    selectable={false}>
                    <Menu.Item key={HOME_URL}>
                        <Link to={HOME_URL} > 
                        <div className='logo'> <img style={{height:'auto', width: 15, marginRight:3, marginBottom:4}} src={dfci_small} alt='' 
                        /> DFCI Proteomics </div> 
                        </Link>
                    </Menu.Item>
                    <SubMenu
                        key='mainMenu'
                        popupClassName = {this.state.showMain && this.props.token ? 'enabledMenu' : 'disabledMenu'}
                          title={
                            <span className={this.state.showMain && this.props.token ? "submenu-title-wrapper arrow_box" : "submenu-title-wrapper"}>
                              <Icon type="home" />
                              Home
                            </span>
                          }
                          onTitleClick={() => {this.props.history.push(HOME_URL)}}
                          onMouseEnter={() => {this.setState({showMain: !this.state.showMain})}}
                          onMouseLeave={() => {setTimeout(() => {this.toggleMain()}, 100)}}
                        >
                        <Menu.Item key={'/search/'}>
                            <Link to={HOME_URL} > 
                              <Icon type="search" />
                            Search 
                            </Link>
                        </Menu.Item>
                        <Menu.Item key={HEATMAP_URL}>
                            <Link to={HEATMAP_URL}> 
                              <Icon type="heat-map" />
                            Heatmap 
                            </Link>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu
                        key='adminMenu'
                        style={admin ? {width: 300} : {display:'none'}}
                        onTitleClick={() => {this.props.history.push(EXPERIMENTS_URL)}}
                        popupClassName = {this.state.showAdmin ? 'enabledMenu' : 'disabledMenu'}
                        title={
                            <span className={this.state.showAdmin ? "submenu-title-wrapper arrow_box" : "submenu-title-wrapper"}>
                              <Icon type="tool" />
                              Admin
                            </span>
                        }
                        onMouseEnter={() => {this.setState({showAdmin: !this.state.showAdmin})}}
                        onMouseLeave={() => {setTimeout(() => {this.toggleAdmin()}, 100)}}
                    >
                        <Menu.Item key={EXPERIMENTS_URL}>
                            <Link to={EXPERIMENTS_URL}> 
                              <Icon type="dot-chart" />
                            Experiments
                            </Link>
                        </Menu.Item>
                        <Menu.Item key={CONFIGURATION_URL}>
                            <Link to={CONFIGURATION_URL} > 
                              <Icon type="setting" />
                            Configuration 
                            </Link>
                        </Menu.Item>
                        <Menu.Item key={USERS_URL}>
                            <Link to={USERS_URL} > 
                              <Icon type="user" />
                            Users 
                            </Link>
                        </Menu.Item>
                        <Menu.Item key={GROUPS_URL}>
                            <Link to={GROUPS_URL}> 
                              <Icon type="team" />
                            Groups 
                            </Link>
                        </Menu.Item>
                    </SubMenu>
                    <Menu.Item key={'logout'} style={{float: 'right'}} onClick={() => {
                        if (! this.props.token) {
                          this.props.toggleLoginModal()
                        } else { 
                          try {
                            this.props.logout(this.props.token)
                          } finally {
                            this.props.setToken(null)
                          }
                      }
                    }}>
                      {!this.props.token? <Icon type="login" /> : null}
                      {!this.props.token ? 'Login' : <div><img style={{ marginRight: 5, maxWidth: 30, height: 'auto', borderRadius: '50%'}} alt='user-avatar' className='userAvatar' src={decode(this.props.token).user_claims.avatar} /> Logout</div>}
                    </Menu.Item>
                </Menu>
        </>
    }
}

export default withRouter(Navbar);