import React, { Component } from 'react'
import { 
  Typography, 
  Divider, 
  Icon, 
  Table, 
  Popconfirm, 
  Checkbox, 
  Button,
  Form,
  Input,
  Select,
  Modal,
  Spin,
 } from 'antd'
import { connect } from 'react-redux'
import _ from 'lodash'

import { 
  getAllConfigurations,
  createConfigurationRequest,
  updateConfigurationRequest,
  deleteConfigurationRequest,
} from '../features/configuration/configurationActions'

import Highlighter from 'react-highlight-words';

const { Option } = Select

const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
  // eslint-disable-next-line
  class extends React.Component {

    render() {
      const { visible, onCancel, onCreate, form } = this.props;
      const { getFieldDecorator } = form;

      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
      };
  
      return (
        <Modal
          visible={visible}
          title="Create Configuration Entry"
          okText="Create"
          onCancel={onCancel}
          onOk={onCreate}
          destroyOnClose={true}
          footer={[
            <Button icon='close-circle' key="back" onClick={onCancel}>
              Cancel
            </Button>,
            <Button icon='plus-circle' key="go" type='primary' onClick={onCreate}>
            Create
          </Button>
          ]}

        >
          <Form  {...formItemLayout}>
            <Form.Item label="Type">
              {getFieldDecorator('type', {
                rules: [{ required: true, message: 'Please input configuration type' }],
              })(<Select placeholder="Select configuration type" style={{ width: 300 }} >
              <Option value="treated_by">Treated By</Option>
              <Option value="principal_investigator">Principal Investigator</Option>
              <Option value="processed_by">Processed By</Option>
              <Option value="submitted_by">Submitted By</Option>
              <Option value="cell_line">Cell Line</Option>
              <Option value="protocol">Protocol</Option>
              <Option value="instrument">Instrument</Option>
        </Select>)}
            </Form.Item>
            <Form.Item label="Value">
              {getFieldDecorator('value', {
                rules: [{ required: true, message: 'Please input configuration value' }],
              })(<Input style={{ width: 300 }} placeholder='Enter value' />)}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  },
);


const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
  state = {
    editing: false,
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = e => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  renderCell = form => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`,
            },
          ],
          initialValue: record[dataIndex],
        })(<Input ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />)}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={this.toggleEdit}
      >
        <Icon style={{color: '#1890FF'}} type='edit' /> {children}
      </div>
    );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}
class Configuration extends Component {
    constructor(props) {
        super(props);
        this.state = {
          searchText: '',
          searchedColumn: '',
          modalVisible: false,
          loading: true,
          configData: null,
          configFilter: 'principal_investigator',
        };
      
        this.columns = [
          {
            title: 'Type',
            dataIndex: 'config_type',
            key: 'config_type',
            // filters: [
            //   {
            //     text: 'Instrument',
            //     value: 'instrument',
            //   },
            //   {
            //     text: 'Protocol',
            //     value: 'protocol',
            //   },
            //   {
            //     text: 'Cell Line',
            //     value: 'cell_line',
            //   },
            //   {
            //     text: 'Submitted By',
            //     value: 'submitted_by',
            //   },
            //   {
            //     text: 'Processed By',
            //     value: 'processed_by',
            //   },
            //   {
            //     text: 'Principal Investigator',
            //     value: 'principal_investigator',
            //   },
            //   {
            //     text: 'Treated By',
            //     value: 'treated_by',
            //   },
            // ],
            // onFilter: (value, record) => {
            //   return record.config_type.indexOf(value) === 0},
            render: (text, record) => {

              return <Select value={record.config_type} style={{ width: 200 }} onChange={(e) => {
                const data = _.cloneDeep(record)
                data.config_type = e
                this.handleSave(data)
              }}>
                <Option value="treated_by">Treated By</Option>
                <Option value="principal_investigator">Principal Investigator</Option>
                <Option value="processed_by">Processed By</Option>
                <Option value="submitted_by">Submitted By</Option>
                <Option value="cell_line">Cell Line</Option>
                <Option value="protocol">Protocol</Option>
                <Option value="instrument">Instrument</Option>
            </Select>
            }},
          {
            title: 'Value',
            key: 'value',
            dataIndex: 'value',
            editable: true,
            sorter: (a, b) => this.sorterHelper(a.value, b.value),
            ...this.getColumnSearchProps('value'),
          },
          {
            title: 'Active',
            key: 'active',
            dataIndex: 'active',
            defaultFilteredValue: [true],
            filters: [
              {
                text: 'Active',
                value: true,
              },
              {
                text: 'Inactive',
                value: false,
              },
            ],
            onFilter: (value, record) => {
              return record.active === value},
            render: (text, record) =>  <Popconfirm title='Toggle active?' onConfirm={() =>this.onCheckChange(record)}><Checkbox checked={record.active}/></Popconfirm>,
          },
          {
            title: 'Delete',
            key: 'delete',
            render: (text, record) => <Popconfirm title='Are you sure?' onConfirm={() => 
              {
                this.props.deleteConfigurationRequest(this.props.token, record.id).then(resp => {
                  this.setState({
                    configData: _.filter(this.props.configuration.all, data => data.config_type === this.state.configFilter)
                  })
                })
              }
              }>
              <Button style={{marginLeft: 10}}icon='close' size='small' type='danger' shape='circle'></Button>
              </Popconfirm>
          },
        ];
    }

    componentDidMount() {
      if (_.isEmpty(this.props.configuration)) {
          this.props.getAllConfigurations(this.props.token).then( () => {
              this.setState({loading: false,
              configData: _.filter(this.props.configuration.all, data => data.config_type === this.state.configFilter)
              })
            })
      } else {
          this.setState({loading: false,
          configData: _.filter(this.props.configuration.all, data => data.config_type === this.state.configFilter)})
      }
  }

  sorterHelper = (a, b) => {
    if (a) {
      return  b ? a.localeCompare(b) : -1
    } else if (b) {
      return a ? b.localeCompare(a) : 1
    } else {
      return -1
    }
  } 

  getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),

        filterIcon: filtered => (
          <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        
        onFilter: (value, record) =>
          record[dataIndex] ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()) : '',
        
            onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
        
        render: text => (
        (this.state.searchedColumn === dataIndex && text)?
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
          : text
        ),
  });
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({ 
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
      });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  handleSave = row => {
    const { id, value, config_type } = row
    this.props.updateConfigurationRequest(this.props.token, { id, value, config_type }).then(resp => { 
    this.setState({
      configData:  _.filter(this.props.configuration.all, data => data.config_type === this.state.configFilter)
    })
  }
    )
  }

  showModal = () => {
    this.setState({
      modalVisible: true,
    });
  };

  handleOk = e => {
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      values['config_type'] = values.type
      delete values.type
      this.props.createConfigurationRequest(this.props.token, values).then(resp => {
        this.setState({
        configData:  _.filter(this.props.configuration.all, data => data.config_type === this.state.configFilter)
        })
      })
      form.resetFields();

    this.setState({
      modalVisible: false,
    });
  })};

  handleCancel = e => {
    this.setState({
      modalVisible: false,
    });
  };
  
  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  onCheckChange(record) {
    this.props.updateConfigurationRequest(this.props.token, {id: record.id, active: ! record.active}).then( resp =>
    this.setState({
      configData:  _.filter(this.props.configuration.all, data => data.config_type === this.state.configFilter)
    }))
  }

  onDropdownChange = (value) => {
  
    this.setState({
      configFilter: value,
      configData: _.filter(this.props.configuration.all, data => data.config_type === value)
    })
  }
  
    render() {
        const { Title } = Typography

        const components = {
          body: {
            row: EditableFormRow,
            cell: EditableCell,
          },
        };
        const columns = this.columns.map(col => {
          if (!col.editable) {
            return col;
          }
        return {
          ...col,
          onCell: record => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave: this.handleSave,
          }),
        };
        });

        return <>
          <Typography>
            <Title level={3}><Icon type='setting'/> System Configuration <Button shape="circle" size='small' icon='plus' onClick={this.showModal} style={{ marginBottom: 16 }}></Button>
            </Title>
          </Typography>
          <Divider />
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <p style={{fontWeight: 500, fontSize: '1.2em', marginRight: 25, marginTop: 4}}>Configuration Filter: </p>
              <Select
              value={this.state.configFilter}
              style={{ width: 200 }}
              optionFilterProp="children"
              onChange={this.onDropdownChange}
              filterOption={(input, option) => {
                return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              }
            >
              <Option value="principal_investigator">Principal Investigator</Option>
              <Option value="instrument">Instrument</Option>
              <Option value="submitted_by">Submitted By</Option>
              <Option value="treated_by">Treated By</Option>
              <Option value="processed_by">Processed By</Option>
              <Option value="protocol">Protocol</Option>
              <Option value="cell_line">Cell Line</Option>
            </Select>
           </div>
          <CollectionCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.modalVisible}
          onCancel={this.handleCancel}
          onCreate={this.handleOk}
        />
          <Modal
          title="Create Configuration Entry"
          visible={false}
          onCancel={this.handleCancel}
          destroyOnClose={true}
          footer={[
            <Button icon='close-square' key="back" onClick={this.handleCancel}>
              Cancel
            </Button>,
          ]}
        >
 
        >
          <Form>
          <Form.Item
          label="Type"
        >(<Select placeholder="Select configuration type" style={{ width: 200 }} >
                <Option value="treated_by">Treated By</Option>
                <Option value="principal_investigator">Principal Investigator</Option>
                <Option value="processed_by">Processed By</Option>
                <Option value="submitted_by">Submitted By</Option>
                <Option value="cell_line">Cell Line</Option>
                <Option value="protocol">Protocol</Option>
                <Option value="instrument">Instrument</Option>
          </Select>)
        </Form.Item>
        <Form.Item
        label="Value"><Input placeholder="Enter value" />
        </Form.Item>
          </Form>
        </Modal>
          { this.state.loading
          ? <Spin />
          : <Table rowKey='id' components={components} columns={columns} dataSource={_.map(this.state.configData, (data => data))} />
          }
          </>
    }
}

const mapStateToProps = ({ configuration }) => {
  return { 
      configuration,
  }
}

export default connect (mapStateToProps, {
  getAllConfigurations,
  createConfigurationRequest,
  updateConfigurationRequest,
  deleteConfigurationRequest,
}) (Configuration)