import { 
    SET_USERS, UPDATE_USER, 
    SET_ACTIVE_USERS, UPDATE_ACTIVE_USER, REMOVE_ACTIVE_USER, REMOVE_USER,
} from './usersConstants'

import ApiService from '../apiService'

export const setUsers = (users) => {
    return {
        type: SET_USERS,
        users,
    }
}

export const updateUser = (userId, data) => {
    return {
        type: UPDATE_USER,
        userId,
        data,
    }
}

export const removeUser = (delUserId) => {
    return {
        type: REMOVE_USER,
        delUserId,
    }
}

export const setActiveUsers = (activeUsers) => {
    return {
        type: SET_ACTIVE_USERS,
        activeUsers,
    }
}

export const updateActiveUser = (activeUserId, activeData) => {
    return {
        type: UPDATE_ACTIVE_USER,
        activeUserId,
        activeData,
    }
}

export const removeActiveUser = (removeUserId) => {
    return {
        type: REMOVE_ACTIVE_USER,
        removeUserId,
    }
}

export const getAllUsers = (token) => async dispatch => {
    try {
        const getResponse = await ApiService.getUsers(token)
        const users = {}
        const active = {}

        if (getResponse) {
            getResponse.data.forEach(element => {
                users[ element.id ] = element
                if (element.active) {
                    active[ element.id ] = element
                }

            })
        }
        dispatch(setUsers(users))
        dispatch(setActiveUsers(active))
    } catch(error) {
        console.warn(error)
    }
}

export const updateUserRequest = (token, data) => async dispatch => {
    try {
        const updateResponse = await ApiService.updateUser(token, data)
        const updateData = updateResponse.data
        if (updateData) {
            dispatch(updateUser(updateData.id, updateData))
            if (data.active || updateResponse.data.active) {
                dispatch(updateActiveUser(updateResponse.data.id, updateResponse.data))
            } else if ( data.active === false ) {
                dispatch(removeActiveUser(updateResponse.data.id))
            }
        }
        return updateData

    } catch(error) {
        console.warn(error)
    }
}

export const deleteUserRequest = (token, userId) => async dispatch => {
    try {
        const deleteResponse = await ApiService.deleteUser(token, userId)
        if (deleteResponse) {
            dispatch(removeUser(userId))
            dispatch(removeActiveUser(userId))
        }
        return deleteResponse.data

    } catch(error) {
        console.warn(error)
    }
}