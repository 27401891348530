import React, { Component } from 'react'
import qs from 'query-string' 
import { withRouter } from "react-router"
import { setToken, logout, toggleLoginModal } from '../../features/token/tokenActions'
import { connect } from 'react-redux'
import { Layout } from 'antd'
import { ContentArea, Navbar } from '../components'
import { NO_AUTH_URLS } from '../constants'

const { Header, Content, Footer } = Layout

class MainAppContainer extends Component {
    componentDidMount() {
        const { token } = qs.parse(this.props.location.search)
        if (token) {
            this.props.setToken(token)
            this.props.history.location.pathname = window.location.origin
        } else if ( ! NO_AUTH_URLS.includes(window.location.pathname)) {
            window.location.href = window.location.origin
        }
    }

    render() {
        const token = this.props.token ? this.props.token.token : null
        const showLogin = this.props.token ? this.props.token.showLogin : null
        return <>
            <Layout className="layout">
              <Header style={{backgroundColor: 'rgb(63, 63, 63)'}}>
                <Navbar setToken={this.props.setToken} logout={this.props.logout} token={token} toggleLoginModal={this.props.toggleLoginModal}/>
              </Header>
              <Content style={{ padding: '0 50px' }}>
                <div style={{ background: '#fff', padding: 24, minHeight: 280 }}>
                    <ContentArea token={token} showLogin={showLogin} toggleLoginModal={this.props.toggleLoginModal} />
                </div>
              </Content>
              <Footer style={{ textAlign: 'center' }}>©2020 Dana-Farber Cancer Institute</Footer>
            </Layout>          
        </>;
    }
}

const mapStateToProps = ({ token }) => {
    return { 
        token
    }
}

export default connect (mapStateToProps, {
    setToken,
    logout,
    toggleLoginModal,
}) (withRouter(MainAppContainer))