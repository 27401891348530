import { 
    SET_HEATMAP_MAX, SET_HEATMAP_MIN, SET_VOLCANO_X, SET_VOLCANO_Y_MIN, SET_VOLCANO_Y_MAX,
    SET_VOLCANO_P, SET_VOLCANO_FC, SET_VOLCANO_UNIQUE, SET_VOLCANO_TYPE, CLICKED_PROTEIN, LOADING_PROTEIN,
    ADD_CUSTOM_PROTEIN, ADD_CUSTOM_COMPOUND, REMOVE_CUSTOM_COMPOUND, REMOVE_CUSTOM_PROTEIN,
    SET_COMPOUND_SEARCH, SET_PROTEIN_SEARCH, SET_CUSTOM_HEATMAP, SET_SHOW_LABEL,
    CLEAR_CUSTOM_COMPOUND, CLEAR_CUSTOM_PROTEIN
} from './resultsConstants'

import ApiService from '../apiService'

export const clearCustomCompound = () => {
    return {
        type: CLEAR_CUSTOM_COMPOUND,
    }
}

export const clearCustomProtein = () => {
    return {
        type: CLEAR_CUSTOM_PROTEIN,
    }
}

export const setCustomHeatmap = (heatmapData) => {
    return {
        type: SET_CUSTOM_HEATMAP,
        heatmapData,
    }
}

export const setShowLabel = (showLabel) => {
    return {
        type: SET_SHOW_LABEL,
        showLabel,
    }
}

export const setCompoundSearch = (compoundSearch) => {
    return {
        type: SET_COMPOUND_SEARCH,
        compoundSearch,
    }
}

export const setProteinSearch = (proteinSearch) => {
    return {
        type: SET_PROTEIN_SEARCH,
        proteinSearch,
    }
}

export const addCustomProtein = (customProteinData) => {
    return {
        type: ADD_CUSTOM_PROTEIN,
        customProteinData,
    }
}

export const addCustomCompound = (customCompoundData) => {
    return {
        type: ADD_CUSTOM_COMPOUND,
        customCompoundData,
    }
}

export const removeCustomProtein = (customProteinId) => {
    return {
        type: REMOVE_CUSTOM_PROTEIN,
        customProteinId,
    }
}

export const removeCustomCompound = (customCompoundId) => {
    return {
        type: REMOVE_CUSTOM_COMPOUND,
        customCompoundId,
    }
}

export const setHeatmapMax = (heatmapMax) => {
    return {
        type: SET_HEATMAP_MAX,
        heatmapMax,
    }
}

export const setClicked = (clicked) => {
    return {
        type: CLICKED_PROTEIN,
        clicked,
    }
}

export const setLoading = (loading) => {
    return {
        type: LOADING_PROTEIN,
        loading,
    }
}

export const setClickedAsync = (clicked) => async dispatch => {
    dispatch(setClicked(clicked))
}

export const setLoadingAsync = (loading) => async dispatch => {
    dispatch(setLoading(loading))
}

export const setHeatmapMin = (heatmapMin) => {
    return {
        type: SET_HEATMAP_MIN,
        heatmapMin,
    }
}

export const setVolcanoX = (volcanoX) => {
    return {
        type: SET_VOLCANO_X,
        volcanoX,
    }
}

export const setVolcanoYMin = (volcanoYMin) => {
    return {
        type: SET_VOLCANO_Y_MIN,
        volcanoYMin,
    }
}

export const setVolcanoYMax = (volcanoYMax) => {
    return {
        type: SET_VOLCANO_Y_MAX,
        volcanoYMax,
    }
}

export const setVolcanoP = (volcanoP) => {
    return {
        type: SET_VOLCANO_P,
        volcanoP,
    }
}

export const setVolcanoFC = (volcanoFC) => {
    return {
        type: SET_VOLCANO_FC,
        volcanoFC,
    }
}

export const setVolcanoUnique = (volcanoUnique) => {
    return {
        type: SET_VOLCANO_UNIQUE,
        volcanoUnique,
    }
}

export const setVolcanoType = (volcanoType) => {
    return {
        type: SET_VOLCANO_TYPE,
        volcanoType,
    }
}

export const clusterHeatmapData = (token, accessions, compoundExperiments) => async dispatch => {
    try {
        const getResponse = await ApiService.clusterHeatmapRequest(token, accessions, compoundExperiments)
            if (getResponse) {
                dispatch(setCustomHeatmap(getResponse.data))
            }

    } catch(error) {
        console.warn(error)
    }
}

export const clearHeatmapData = () => async dispatch => {
    try {
        dispatch(setCustomHeatmap(null))
        dispatch(clearCustomCompound(null))
        dispatch(clearCustomProtein(null))

    } catch(error) {
        console.warn(error)
    }
}

