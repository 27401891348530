import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setUsers, getAllUsers, updateUserRequest} from '../features/users/usersActions'
import { getAllExperiments } from '../features/experiments/experimentsActions'
import { 
    getAllGroups, 
    addUserToGroup, 
    removeUserFromGroup,
    updateGroupRequest,
    createGroupRequest,
    deleteGroupRequest,
    addExperimentToGroup,
    removeExperimentFromGroup,
} from '../features/groups/groupsActions'
import { 
    Select, 
    Spin, 
    Table, 
    Input, 
    Button, 
    Icon, 
    Typography, 
    Divider,
    Checkbox,
    Modal,
    Form,
    Popconfirm,
    notification,
 } from 'antd'
import Highlighter from 'react-highlight-words';
import _ from 'lodash'

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
  state = {
    editing: false,
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = e => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  renderCell = form => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: true,
              message: `${title} is required.`,
            },
          ],
          initialValue: record[dataIndex],
        })(<Input ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />)}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={this.toggleEdit}
      >
        <Icon style={{color: '#1890FF'}} type='edit' /> {children}
      </div>
    );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}

class Group extends Component {
    constructor(props) {
        super(props);

        this.textInput = React.createRef()

        this.state = {  
            loading: true,
            visible: false,
            modal: {
                title: null,
                action: null,
                arg_one: null,
                arg_two: null,
                message: null,
                mouseX: null,
                mouseY: null,
            }
        };

        this.columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                sorter: (a, b) => a.id - b.id,
                defaultSortOrder: 'descend',
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                editable: true,
                sorter: (a, b) => this.sorterHelper(a.name, b.name),
                ...this.getColumnSearchProps('name'),
            },
            {
                title: 'Users',
                key: 'users',
                render: (text, record ) => this.renderUsers(record),
                width: 350,
            },
            {
              title: 'Experiments',
              key: 'experiments',
              render: (text, record ) => this.renderExperiments(record),
          },
          {
                title: 'Active',
                key: 'active',
                render: (text, record) =>  <Popconfirm title='Toggle active?' onConfirm={() =>this.onCheckChange(record)}><Checkbox checked={record.active}/></Popconfirm>,
                defaultFilteredValue: [true],
                filters: [
                  {
                    text: 'Active',
                    value: true,
                  },
                  {
                    text: 'Inactive',
                    value: false,
                  },
                ],
                onFilter: (value, record) => {
                  return record.active === value},
            },
            {
              title: 'Delete',
              key: 'delete',
              render: (text, record) => <Popconfirm title='Are you sure?' onConfirm={() => this.props.deleteGroupRequest(this.props.token, record.id)}>
                <Button style={{marginLeft: 10}}icon='close' size='small' type='danger' shape='circle'></Button>
                </Popconfirm>
            }
          ]
    }

    renderUsers = (record) => {
        const { Option } = Select
        const options = []

        if (this.props.groups) {
            let sortedActiveUsers = []
            if (! _.isEmpty(this.props.users.active)) {
              sortedActiveUsers = Object.values(this.props.users.active).sort(this.emailSorterHelper)
            }
            sortedActiveUsers.forEach(user =>  {
                options.push(<Option key={user.email} value={user.email} name={user.email}>{user.email}</Option>)
            })
        }

        const sortedAllUsers = this.props.groups['all'][record.id].users.sort(this.emailSorterHelper)

        return <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Select users"
            value={sortedAllUsers.map((obj) => {
                return this.props.users['all'] ? this.props.users['all'][obj.id].email : obj.email
              })
            }
            optionFilterProp='name'
            onSelect={(e) => this.handleGroupAdd(e, record)}
            onDeselect={(e) => this.handleGroupDelete(e, record)}
      >
        {options}
      </Select>
    }

    renderExperiments = (record) => {
        const { Option } = Select
        const options = []

        if (this.props.groups) {
          let sortedActiveExperiments = []
          if ( ! _.isEmpty(this.props.experiments.active) ) {
            sortedActiveExperiments = Object.values(this.props.experiments.active).sort(this.nameSorterHelper)
          } 
          sortedActiveExperiments.forEach(experiment =>  {
                options.push(<Option key={experiment.name} value={experiment.name} name={experiment.name}>{experiment.name}</Option>)
            })
        }
        const sortedAllExperiments = this.props.groups['all'][record.id].experiments.sort(this.nameSorterHelper)

        return <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Select experiments"
            value={sortedAllExperiments.map((obj) => this.props.experiments['all'] ? this.props.experiments['all'][obj.id].name : obj.name)}
            optionFilterProp='name'
            onSelect={(e) => this.handleExperimentAdd(e, record)}
            onDeselect={(e) => this.handleExperimentDelete(e, record)}
      >
        {options}
      </Select>
    }

    sorterHelper = (a, b) => {
        if (a) {
          return  b ? a.localeCompare(b) : -1
        } else if (b) {
          return a ? b.localeCompare(a) : 1
        } else {
          return -1
        }
      } 

      emailSorterHelper = (a, b) => {
        if (a) {
          return  b ? String(a.email).toLowerCase().localeCompare(String(b.email).toLowerCase()) : -1
        } else if (b) {
          return a ? String(b.email).toLowerCase().localeCompare(String(a.email).toLowerCase()) : 1
        } else {
          return -1
        }
      } 

     nameSorterHelper = (a, b) => {
        if (a) {
          return  b ? String(a.name).toLowerCase().localeCompare(String(b.name).toLowerCase()) : -1
        } else if (b) {
          return a ? String(b.name).toLowerCase().localeCompare(String(a.name).toLowerCase()) : 1
        } else {
          return -1
        }
      } 
   
    getColumnSearchProps = dataIndex => ({
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
              <Input
                ref={node => {
                  this.searchInput = node;
                }}
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
              />
              <Button
                type="primary"
                onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                icon="search"
                size="small"
                style={{ width: 90, marginRight: 8 }}
              >
                Search
              </Button>
              <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </div>
          ),

          filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
          ),
          
          onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()) : '',
          
              onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => this.searchInput.select());
            }
          },
          
          render: text => (
          (this.state.searchedColumn === dataIndex && text)?
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text.toString()}
            />
            : text
          ),
    });
  
    handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      this.setState({ 
        searchText: selectedKeys[0],
        searchedColumn: dataIndex,
        });
    };
  
    handleReset = clearFilters => {
      clearFilters();
      this.setState({ searchText: '' });
    };

    showModal = () => {
        this.setState({
          visible: true,
        });
      };
    
      handleOk = e => {

        if (! this.state.modal.arg_two) {
            this.state.modal.action(this.props.token, this.state.modal.arg_one)
        } else {
            this.state.modal.action(this.props.token, this.state.modal.arg_one, this.state.modal.arg_two)
        }
        this.setState({
          visible: false,
          modal: {
              title: null,
              action: null,
              message: null,
              arg_one: null,
              arg_two: null,
              mouseX: null,
              mouseY: null,
          }
        });
      };
    
      handleCancel = e => {
        this.setState({
          visible: false,
          modal: {
            title: null,
            action: null,
            message: null,
            arg_one: null,
            arg_two: null,
            mouseX: null,
            mouseY: null,
        }
        });
      };
      handleExperimentAdd(e, record) {
        const targetExperiment = _.map(this.props.experiments.all, (data => data)).find( (experiment) => {
          return experiment.name === e
        })
        this.props.addExperimentToGroup(this.props.token, targetExperiment.id, record.id)
  
        // this.setState({
        //     visible: true,
        //     modal: {
        //         title: `Edit Group: ${record.name}`,
        //         message: <div><Icon style={{color: '#faad14'}} type='exclamation-circle' theme="filled"/> {`Add ${targetExperiment.name} to group ${record.name}?`}</div>,
        //         action: this.props.addExperimentToGroup,
        //         arg_one: targetExperiment.id,
        //         arg_two: record.id,
        //         mouseX: this.props.mouseX,
        //         mouseY: this.props.mouseY,
        //     }
        // })
    }

    handleExperimentDelete(e, record) {
      const targetExperiment = _.map(this.props.experiments.all, (data => data)).find( (experiment) => {
        return experiment.name === e
      })
      this.props.removeExperimentFromGroup(this.props.token, targetExperiment.id, record.id)

      // this.setState({
      //       visible: true,
      //       modal: {
      //           title: `Edit Group: ${record.name}`,
      //           message: <div><Icon style={{color: '#faad14'}} type='exclamation-circle' theme="filled"/> {`Remove ${targetExperiment.name} from group ${record.name}?`}</div>,
      //           action: this.props.removeExperimentFromGroup,
      //           arg_one: targetExperiment.id,
      //           arg_two: record.id,
      //           mouseX: this.props.mouseX,
      //           mouseY: this.props.mouseY,
      //       }
      //   })
    }
 
    handleGroupAdd(e, record) {
      const targetUser = _.map(this.props.users.all, (data => data)).find( (user) => {
        return user.email === e
      })
      this.props.addUserToGroup(this.props.token, targetUser.id, record.id)

        // this.setState({
        //     visible: true,
        //     modal: {
        //         title: `Edit Group: ${record.name}`,
        //         message: <div><Icon style={{color: '#faad14'}} type='exclamation-circle' theme="filled"/> {`Add ${e} to group ${record.name}?`}</div>,
        //         action: this.props.addUserToGroup,
        //         arg_one: targetUser.id,
        //         arg_two: record.id,
        //         mouseX: this.props.mouseX,
        //         mouseY: this.props.mouseY,
        //     }
        // })
    }

    handleGroupDelete(e, record) {
        const targetUser = _.map(this.props.users.all, (data => data)).find( (user) => {
          return user.email === e
        })
        this.props.removeUserFromGroup(this.props.token, targetUser.id, record.id)

        // this.setState({
        //     visible: true,
        //     modal: {
        //         title: `Edit Group: ${record.name}`,
        //         message: <div><Icon style={{color: '#faad14'}} type='exclamation-circle' theme="filled"/> {`Remove ${e} from group ${record.name}?`}</div>,
        //         action: this.props.removeUserFromGroup,
        //         arg_one: targetUser.id,
        //         arg_two: record.id,
        //         mouseX: this.props.mouseX,
        //         mouseY: this.props.mouseY,
        //     }
        // })
    }

    handleAdd = () => {
        const allId = _.map(this.props.groups.all, (obj => obj.id))
        this.props.createGroupRequest(this.props.token, {name: `NewGroup${Math.max(...allId)}`}).then(() => {
          this.focusTextInput()
        })
    }
    
    handleSave = row => {
        this.props.updateGroupRequest(this.props.token, {id: row.id, name: row.name})
    }
    
    componentDidMount() {
      let promises = [
        this.props.getAllExperiments(this.props.token),
        this.props.getAllGroups(this.props.token),
        this.props.getAllUsers(this.props.token),
      ]
      Promise.all(promises).then( value => this.setState({loading: false}))    
    }

    focusTextInput() {
      // Explicitly focus the text input using the raw DOM API
      // Note: we're accessing "current" to get the DOM node
      if (this.textInput.current) {
        this.textInput.current.toggleEdit()
      } else{
        notification.warning({placement: 'bottomLeft', message: 'Table Filter', description:'Current table filters prevent the new group from showing. Please refresh to see your new group.'})

      }
    }

    onCheckChange(record) {
      this.props.updateGroupRequest(this.props.token, {id: record.id, active: ! record.active})
    }

    render() {
        const components = {
            body: {
              row: EditableFormRow,
              cell: EditableCell,
            },
          };
          const columns = this.columns.map(col => {
            if (!col.editable) {
              return col;
            }
            return {
              ...col,
              onCell: record => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: this.handleSave,
                ref: record.id === Math.max(..._.map(this.props.groups.all, (obj => obj.id))) ? this.textInput : null,
              }),
            };
          });

        const { Title } = Typography
        
        return <>
            <Typography>
                  <Title level={3}> <Icon type='team' /> Groups <Popconfirm 
                  title={`Create group "NewGroup${Math.max(..._.map(this.props.groups.all, (obj => obj.id)))}"?`} 
                  onConfirm={this.handleAdd}>
                    <Button shape="circle" size='small' icon='plus'  style={{ marginBottom: 16 }}></Button>
                  </Popconfirm>
                </Title>
            </Typography>
            <Divider />
            { this.state.loading
            ? <Spin />
            : <div><Modal
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            style={{maxWidth: 180, minWidth: 180, display: 'flex', position:'absolute', 
            left: (this.state.modal.mouseX + 90 < window.innerWidth)
              ? this.state.modal.mouseX - 90 : window.innerWidth-180, 
            top: (this.state.modal.mouseY + 180 < window.innerHeight)
              ? this.state.modal.mouseY : window.innerHeight - 180}}
            mask={false}
            closable={false}
            destroyOnClose={true}
          >
            {this.state.modal.message}
          </Modal>
           <Table   
           components={components}
           rowKey={'id'}
           columns={columns} 
           dataSource={_.map(this.props.groups.all, (data => data))}
         /></div>}
        </>
    }
}

const mapStateToProps = ({ users, groups, experiments }) => {
    return { 
        users,
        groups,
        experiments
    }
}

export default connect (mapStateToProps, {
    getAllExperiments,
    getAllUsers,
    getAllGroups,
    removeUserFromGroup,
    addUserToGroup,
    setUsers,
    updateUserRequest,
    updateGroupRequest,
    createGroupRequest,
    deleteGroupRequest,
    addExperimentToGroup,
    removeExperimentFromGroup,
}) (Group)