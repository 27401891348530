import React, { Component } from 'react'
import {
    Experiment,
    User,
    Group,
    Configuration,
    Home,
    CustomHeatmap,
    Inactive,
} from '../../screens'
import {
    Switch,
    Route,
    Redirect
} from 'react-router-dom'
import { withRouter } from "react-router";
import { 
    USERS_URL, 
    EXPERIMENTS_URL, 
    GROUPS_URL, 
    HOME_URL,
    CONFIGURATION_URL,
    HEATMAP_URL,
    INACTIVE_LOGIN_URL,
} from '../constants'

const HomeRedirect = ({ location }) => <Redirect to={HOME_URL} />

class ContentArea extends Component {
    constructor(props) {
        super(props);
        // this.handleMouseMove = this.handleMouseMove.bind(this);
        this.state = { x: 0, y: 0 };
      }
    
    // handleMouseMove(event) {
    //     if (this.props.location.pathname === GROUPS_URL || this.props.location.pathname === USERS_URL) {
    //         this.setState({
    //             x: event.clientX,
    //             y: event.clientY
    //         });
    //     }
    //   }
    
    render() {
        return (
            <div className={'contentarea'} onMouseMove={this.handleMouseMove}>
                    <Switch>
                        <Route render={(props) => <Experiment {...props} token={this.props.token} />} path={EXPERIMENTS_URL} />
                        <Route render={(props) => <Group {...props} token={this.props.token} mouseX={this.state.x} mouseY={this.state.y}/>} path={GROUPS_URL} />
                        <Route render={(props) => <User {...props} token={this.props.token} mouseX={this.state.x} mouseY={this.state.y}/>} path={USERS_URL} />
                        <Route render={(props) => <Configuration {...props} token={this.props.token}/>} path={CONFIGURATION_URL} />
                        <Route render={(props) => <Inactive {...props} token={this.props.token}/>} path={INACTIVE_LOGIN_URL} />
                        <Route render={(props) => <CustomHeatmap {...props} token={this.props.token} />} path={HEATMAP_URL} />
                        <Route render={(props) => <Home {...props} token={this.props.token} showLogin={this.props.showLogin} toggleLoginModal={this.props.toggleLoginModal}/>} path={HOME_URL} />
                        <Route component={HomeRedirect} />
                    </Switch>
            </div>
        );
    }
}

export default withRouter(ContentArea)