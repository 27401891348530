import React, { Component } from 'react'
import Highlighter from 'react-highlight-words'
import { 
  Modal, 
  Typography, 
  Divider, 
  Icon,
  Radio,
  Row, 
  Col,
  Button,
  AutoComplete,
  Input,
  Tooltip,
  Select,
  Spin,
  Slider,
  InputNumber,
  Table,
  Switch,
  notification,
} from 'antd'
import { 
  LOGIN_ENDPOINT, 
  PROTEIN_SEARCH_MIN,
  BACKEND_URL,
  EXPORT_EXPERIMENTS_URL,
  ERROR_COLOR
} from '../shared/constants'
import GoogleButton from 'react-google-button'
import { 
  Volcano,
  Heatmap
} from '.'
import { connect } from 'react-redux'
import { 
  getResultDataRequest,
  getAllCompounds,
  getAllProteins,
  getExperimentResultDataRequest,
  setSearchedExperiment,
 } from '../features/experiments/experimentsActions'

import {
  setHeatmapMax,
  setHeatmapMin,
  setVolcanoX,
  setVolcanoYMin,
  setVolcanoYMax,
  setVolcanoP,
  setVolcanoFC,
  setVolcanoUnique,
  setVolcanoType,
  setClickedAsync,
  setLoading,
  addCustomProtein,
  addCustomCompound,
  setShowLabel,
} from '../features/results/resultsActions'
import _ from 'lodash'
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import protein from '../assets/protein.png'
import compound from '../assets/compound.png'

const { Option } = Select
const COMPOUND_QUERY = 'compound'
const PROTEIN_QUERY = 'protein'
const TREATMENT_QUERY = 'treatment'

const SQUARE = 'square'
const HYPERBOLIC = 'hyperbolic'
const X_SLIDER_MAX = 0
const X_SLIDER_MIN = -10
const Y_SLIDER_MAX = 5
const Y_SLIDER_MIN = -5

const DEFAULT_UNIQUE = 2
const DEFAULT_FC = 2
const DEFAULT_P = -3
const PRECISION = 4

class Home extends Component {
  constructor(props) {
    super(props)
    const {results, addCustomProtein, addCustomCompound} = this.props
    const {heatmapMin, heatmapMax, volcanoX, volcanoYMin, volcanoYMax,
      volcanoP, volcanoFC, volcanoUnique, volcanoType, 
     } = results

     const { Text } = Typography
     this.columns = [
      {
        title: 'Add to Custom Heatmap',
        key: 'custom_heatmap',
        width: 200,
        render: (text, record) => {
          return <Button style={{marginLeft: 63}} onClick={() => {
            const { meta } = this.props.experiments.searchedExperiment
            addCustomProtein({id: `${record.accession_name} (${record.gene_symbol})`, key: String(record.accession_id)})
            addCustomCompound({id: `${record.treatment_name} (${meta.name})`, key: `${record.treatment_id}-${meta.id}`})
            notification.success({placement: 'bottomRight', message: 'Success', description: 'Successfully added to custom heatmap!', style: {backgroundColor: ERROR_COLOR} })
          }} icon='heat-map' />
        }
      },
      {
        title: 'Accession',
        dataIndex: 'accession_name',
        key: 'accession_name',
        sorter: (a, b) => this.sorterHelper(a.accession_name, b.accession_name),
        ...this.getColumnSearchProps('accession_name'),
      },
      {
      title: 'Gene Symbol',
      dataIndex: 'gene_symbol',
      key: 'gene_symbol',
      sorter: (a, b) => this.sorterHelper(a.gene_symbol, b.gene_symbol),
      ...this.getColumnSearchProps('gene_symbol'),
      },
      {
        title: 'Compound Name',
        dataIndex: 'compound_name',
        key: 'compound_name',
        sorter: (a, b) => this.sorterHelper(a.compound_name, b.compound_name),
        ...this.getColumnSearchProps('compound_name'),
      },
      {
        title: 'Treatment Name',
        dataIndex: 'treatment_name',
        key: 'treatment_name',
        sorter: (a, b) => this.sorterHelper(a.treatment_name, b.treatment_name),
        ...this.getColumnSearchProps('treatment_name'),
      },
      {
        title: <Text>Log<sub>2</sub> Fold Change</Text>,
        dataIndex: 'log_fc',
        key: 'log_fc',
        sorter: (a, b) => a.log_fc - b.log_fc,
        ...this.getColumnSearchProps('log_fc'),
        render: (text) => Number.parseFloat(text).toPrecision(PRECISION),
      },
      {
        title: <Text>Log<sub>10</sub> P-Value</Text>,
        dataIndex: 'neg_log10_p_value',
        key: 'neg_log10_p_value',
        sorter: (a, b) => a.neg_log10_p_value - b.neg_log10_p_value,
        ...this.getColumnSearchProps('neg_log10_p_value'),
        render: (text) => Number.parseFloat(text).toPrecision(PRECISION),
      },
      {
        title: `Pi`,
        dataIndex: 'pi',
        key: 'pi',
        sorter: (a, b) => a.pi - b.pi,
        ...this.getColumnSearchProps('pi'),
        render: (text) => Number.parseFloat(text).toPrecision(PRECISION),
      },
    ]

    this.state = { 
      visible: false,
      queryType: COMPOUND_QUERY,
      searchValue: '',
      loading : false,
      heatmapColors: [
        'Greys','YlGnBu','Greens','YlOrRd',
        'Bluered','RdBu','Reds','Blues','Picnic',
        'Rainbow','Portland','Jet','Hot','Blackbody',
        'Earth','Electric','Viridis','Cividis'
      ],
      heatmapColorSelected: 'RdBu',
      heatmapMin,
      heatmapMax,
      volcanoX,
      volcanoYMin,
      volcanoYMax,
      volcanoP,
      volcanoFC,
      volcanoUnique,
      volcanoType,
      hits: [],
      hitData: [],
    }
  }

  componentDidUpdate(prevProps) {
    for ( let key of Object.keys(this.props.results)) {
      if( prevProps.results[key] !== this.props.results[key] && prevProps.results[key] !== 'searchedExperiment' ) {
        const {heatmapMin, heatmapMax, volcanoX, volcanoYMin, volcanoYMax,
            volcanoP, volcanoFC, volcanoUnique, volcanoType
           } = this.props.results

        const resultData = this.getResults()
        const hits = this.getHits(resultData)
        const hitData = this.getHitData(resultData)

        this.setState({
              heatmapMin,
              heatmapMax,
              volcanoX,
              volcanoYMin,
              volcanoYMax,
              volcanoP,
              volcanoFC,
              volcanoUnique,
              volcanoType,
              hits,
              hitData,
            })
        break
        }
      }
  }

    componentDidMount() {
      const { token, getAllCompounds, getAllProteins, setVolcanoUnique, setSearchedExperiment } = this.props
      setVolcanoUnique(DEFAULT_UNIQUE)
      setSearchedExperiment(null)
      if (token) {
        let promises = [
          getAllCompounds(token),
          getAllProteins(token),
        ]
        Promise.all(promises).then( value => this.setState(
          {
            loading: false, 
          })
        )
      }
    }
  
    handleOk = e => {
        this.props.toggleLoginModal()
        window.location.href = LOGIN_ENDPOINT
    };

    getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              this.searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => this.handleTableSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => this.handleTableSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      
      filterIcon: filtered => (
        <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      
      onFilter: (value, record) =>
        record[dataIndex] ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()) : '',
      
          onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => this.searchInput.select());
        }
      },
      
      render: text => (
      (this.state.searchedColumn === dataIndex && text)?
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
        : text
      ),
});

handleTableSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({ 
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
    });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};
    sorterHelper = (a, b) => {
      if (a) {
        return  b ? a.localeCompare(b) : -1
      } else if (b) {
        return a ? b.localeCompare(a) : 1
      } else {
        return -1
      }
    } 
  
    handleCancel = e => {
      this.props.toggleLoginModal()
    };

    handleSearch = () => {
      const { searchValue, queryType } = this.state
      const { getResultDataRequest, token, experiments, setVolcanoX, setVolcanoYMin, setVolcanoYMax, 
        setVolcanoType, setVolcanoUnique, setVolcanoFC, setVolcanoP} = this.props
      const { allProteins, allCompounds } = experiments

      const queryTarget = _.filter(
        queryType === COMPOUND_QUERY ? allCompounds : allProteins,

        record => {
          let regExp = /\(([^)]+)\)/
          if (queryType === COMPOUND_QUERY) {
            return record.name === searchValue
          } else {
            let matches = regExp.exec(searchValue)
            return Boolean(matches) && record.name === matches[1]
          }
        }
      )

      if (Boolean(queryTarget)) {
        const queryObject = queryTarget[0]

        if (Boolean(queryObject)) {
          const target = queryObject[queryType === COMPOUND_QUERY ? 'compound_id' : 'accession_id']
          this.setState({
            loading: true
          })
          setVolcanoType(null)
          getResultDataRequest(token, target, queryType).then( (resp) => {
            if (resp) {
              const resultData = this.getResults()
              const hits = this.getHits(resultData)
              const hitData = this.getHitData(resultData)

              const logFCValues =  _.map(resultData, 'log_fc') 
              const volcanoX = 0

              const volcanoYMin = logFCValues.length ? _.min(logFCValues) - 0.25 : 0
              const volcanoYMax = logFCValues.length ? _.max(logFCValues) + 0.25 : 0

              setVolcanoX(volcanoX)
              setVolcanoYMin(volcanoYMin)
              setVolcanoYMax(volcanoYMax)

              setVolcanoUnique(DEFAULT_UNIQUE)
              setVolcanoFC(DEFAULT_FC)
              setVolcanoP(DEFAULT_P)

              this.setState({loading: false, hits, hitData,  volcanoX, volcanoYMax, volcanoYMin, volcanoType: null,
                })
              } else {
                this.setState({loading: false})
              }
            }
          )
        }
      }
    }

    onExperimentDropdownChange = (experimentId) => {
      const { getExperimentResultDataRequest, token, experiments, setVolcanoX, setVolcanoYMin, setVolcanoYMax,
        setVolcanoType, setVolcanoUnique, setVolcanoP, setVolcanoFC} = this.props
      const { searchedExperiment } = experiments

      this.setState({
        loading: true
      })
      setVolcanoType(null)
      getExperimentResultDataRequest(token, searchedExperiment.compound.compound_id, COMPOUND_QUERY, experimentId).then( 
        (resp) => {
          if (resp) {
            const resultData = this.getResults()
            const hits = this.getHits(resultData)
            const hitData = this.getHitData(resultData)

            const logFCValues =  _.map(resultData, 'log_fc') 
            const volcanoX = 0

            const volcanoYMin = logFCValues.length ? _.min(logFCValues) - 0.25 : 0
            const volcanoYMax = logFCValues.length ? _.max(logFCValues) + 0.25 : 0

            setVolcanoX(volcanoX)
            setVolcanoYMin(volcanoYMin)
            setVolcanoYMax(volcanoYMax)

            setVolcanoUnique(DEFAULT_UNIQUE)
            setVolcanoFC(DEFAULT_FC)
            setVolcanoP(DEFAULT_P) 

            this.setState({loading: false, hits, hitData,  volcanoX, volcanoYMax, volcanoYMin, volcanoType: null,
              })
            } else {
              this.setState({loading: false})
            }
          }           
        )
    }

  onCompoundDropdownChange = (inputValue) => {
    const { token, experiments, getExperimentResultDataRequest, setVolcanoX, setVolcanoYMin, setVolcanoYMax, 
      setVolcanoType, setVolcanoUnique, setVolcanoFC, setVolcanoP} = this.props
    const { searchedExperiment } = experiments
    this.setState({loading: true})
    const value = _.split(inputValue, '/')
    const compound = value[0]
    const treatment = value[1]
    setVolcanoType(null)

    getExperimentResultDataRequest(token, treatment, TREATMENT_QUERY, searchedExperiment.meta.id, true, `?foo=[${compound}]`).then(
      (resp) => {
        if (resp) {
          const resultData = this.getResults()
          const hits = this.getHits(resultData)
          const hitData = this.getHitData(resultData)

          const logFCValues =  _.map(resultData, 'log_fc') 
          const volcanoX = 0

          const volcanoYMin = logFCValues.length ? _.min(logFCValues) - 0.25 : 0
          const volcanoYMax = logFCValues.length ? _.max(logFCValues) + 0.25 : 0          

          setVolcanoX(volcanoX)
          setVolcanoYMin(volcanoYMin)
          setVolcanoYMax(volcanoYMax)

          setVolcanoUnique(DEFAULT_UNIQUE)
          setVolcanoFC(DEFAULT_FC)
          setVolcanoP(DEFAULT_P)

          this.setState({loading: false, hits, hitData,  volcanoX, volcanoYMax, volcanoYMin, volcanoType: null, searchValue: resp.compound.compound_name
            })
        } else {
          this.setState({loading: false})
        }
      }
    )
  }

    showModal = () => {
        this.setState({
          visible: true,
        });
      };
    
    filterSearch() {
      const { queryType, searchValue } = this.state
      const {allProteins, allCompounds} = this.props.experiments

      return queryType === COMPOUND_QUERY ? _.map(allCompounds, 'name').sort(this.sorterHelper)
      : searchValue && searchValue.length >= PROTEIN_SEARCH_MIN ? _.map(allProteins, (record) => {
        return `${record['gene_symbol']} (${record['name']})`
      }).sort(this.sorterHelper) : []
    }

    setQueryType = (e) => {
      this.setState({
        queryType: e.target.value
      })
    }

    _handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        this.handleSearch();
      }
    }

    getExperimentOptions() {
      let options = []
      const { searchedExperiment } = this.props.experiments
      if (Boolean(searchedExperiment)) {
        const { experiments } = 'compound' in searchedExperiment ?  searchedExperiment.compound : searchedExperiment.protein

        experiments.forEach(element => {
          options.push(<Option key={element.id} value={element.id}>{element.name}</Option>)
        })
      }
      return options
    }

    getSecondaryOptions() {
      let options = []
      let added = []
      const { searchedExperiment } = this.props.experiments

      const secondaryOptions = searchedExperiment && searchedExperiment.meta ? searchedExperiment.meta.compound : []

      secondaryOptions.forEach(element => {
        if (! (added.includes(`${element.id}/${element.treatment_id}`))) {
          added.push(`${element.id}/${element.treatment_id}`)
          options.push(<Option key={`${element.id}/${element.treatment_id}`} value={`${element.id}/${element.treatment_id}`}>{element.treatment_name} ({element.name})</Option>)
        }
      })
      return options
    }

    getTertiaryOptions() {
      let options = []
      const { searchedExperiment } = this.props.experiments

      if (Boolean(searchedExperiment)) {
        const { experiments } = 'compound' in searchedExperiment ?  searchedExperiment.compound : searchedExperiment.protein

        experiments.forEach(element => {
          options.push(<Option key={element.id} value={element.id}>{element.cell_line} ({element.name})</Option>)
        })
      }
      return options    
    }

    getHeatmapStyleOptions() {
      let options = []
      const { heatmapColors } = this.state

      heatmapColors.forEach(element => {
        options.push(<Option key={element} value={element}>{element}</Option>)
      })
      return options
    }

    getResults() {
      const { experiments, results } = this.props
      const { searchedExperiment } = experiments 
      return searchedExperiment ? 
        ! searchedExperiment.meta.filter_label ? 
          _.filter(searchedExperiment.data, obj => {
            try {
              return obj.unique_peptides >= results.volcanoUnique
            } catch {
              return false
            }
          }) 
        : searchedExperiment.data
      : []
    }

    getHits(resultData) {
        return resultData && resultData.length ? _.filter(resultData, obj => this.isHit(obj)) : []
    }

    getHitData(resultData){
      return _.map(resultData, obj => this.isHit(obj))
    }

    isHit(record) {
      const { volcanoFC, volcanoP, volcanoType, volcanoUnique } = this.props.results
      const useDefault = _.isNil(volcanoFC) || _.isNil(volcanoP) || _.isNil(volcanoType)

      if (useDefault) { 
        return record.default_hit && record.unique_peptides >= volcanoUnique

      } else {

        if ( volcanoType === SQUARE ) {
          return (record.neg_log10_p_value <= volcanoP) && 
            (record.log_fc >= 0 ? 
              record.log_fc >= Math.abs(Math.log2(volcanoFC)) : 
              record.log_fc <= (Math.abs(Math.log2(volcanoFC)) * -1))

        } else {
          const piUp =  Math.abs(Math.log2(volcanoFC) * volcanoP)
          const piDown = Math.abs(Math.log2(volcanoFC) * volcanoP) * -1
    
          return record.log_fc >= 0 ? record.pi >= piUp : record.pi <= piDown
        }
      }
    }
  
    render() {
        const { Title, Paragraph, Text } = Typography
        const { queryType, searchValue, loading,  heatmapColorSelected, hits, hitData } = this.state
        const { experiments, setHeatmapMax, setHeatmapMin, results, setClickedAsync, setLoading,
          setVolcanoFC, setVolcanoP, setVolcanoType, setVolcanoUnique, setVolcanoX, setVolcanoYMin, setVolcanoYMax,
          addCustomProtein, addCustomCompound,
         } = this.props
        const { searchedExperiment } = experiments
        const filterLabel = searchedExperiment ? searchedExperiment.meta.filter_label : false

         if ( results && results.clicked) {
          setClickedAsync(false).then( (resp) => {
              setVolcanoType(null)
              setVolcanoUnique(DEFAULT_UNIQUE)
              setVolcanoP(DEFAULT_P)
              setVolcanoFC(DEFAULT_FC)
              setLoading(false)

              if (filterLabel) {
                this.setState({queryType: PROTEIN_QUERY, volcanoType: null, hits: [], hitData: [], searchValue: searchedExperiment.meta.filter_label + " (" +  searchedExperiment.meta.filter_name + ")"})
              
              } else {
                setVolcanoType(null) 
                const resultData = this.getResults()
                const hits = this.getHits(resultData)
                const hitData = this.getHitData(resultData)
    
                const logFCValues =  _.map(resultData, 'log_fc') 
                const volcanoX = 0
                const volcanoYMin = logFCValues.length ? _.min(logFCValues) - 0.25 : 0
                const volcanoYMax = logFCValues.length ? _.max(logFCValues) + 0.25 : 0

                setVolcanoX(volcanoX)
                setVolcanoYMin(volcanoYMin)
                setVolcanoYMax(volcanoYMax)
                
                this.setState({queryType: COMPOUND_QUERY, searchValue: searchedExperiment.compound.compound_name, volcanoType: null,
                  hits, hitData, volcanoX, volcanoYMax, volcanoYMin})
              }
             }
            )
         }

        if (loading || results.loading) {
          return <Spin />
        }

        if (this.props.token) {
            return <>
              <Typography>
                <Title level={3}><Icon type='search' /> {`Search${Boolean(searchedExperiment)? ': '+ (! searchedExperiment.meta.filter_label ? searchedExperiment.meta.filter_name : searchedExperiment.meta.filter_label.split(';')[0] + " (" +  searchedExperiment.meta.filter_name + ")") : ''}`}</Title>
              </Typography>
              <Divider />
              <Row gutter={8}>
                  <Col span={6}>
                    <Radio.Group size='large' value={this.state.queryType} onChange={this.setQueryType}>
                      <Radio.Button value={COMPOUND_QUERY}>Compound</Radio.Button>
                      <Radio.Button value={PROTEIN_QUERY}>Protein</Radio.Button>
                    </Radio.Group>
                  </Col>
                  <Col span={12}>
                    <AutoComplete 
                      size='large'
                      style={
                        {
                          width: '100%'
                        }
                      } 
                      value={searchValue}
                      onChange={value => this.setState({searchValue: value})}
                      dataSource={this.filterSearch()}
                      placeholder={`Enter ${queryType}${queryType === PROTEIN_QUERY ? ' - minimum ' + String(PROTEIN_SEARCH_MIN) + ' characters' : ''}`}
                      filterOption={(inputValue, option) => {
                        return option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      }
                      }
                    >
                      <Input 
                      onKeyDown={this._handleKeyDown}
                      value={searchValue}
                      suffix={
                        <>
                          <Icon 
                            style={! Boolean(searchValue) ? {display: 'none'}: {marginRight: 5}} 
                            type="close-circle" 
                            theme="filled" 
                            onClick={() => this.setState({searchValue: ''})}
                          />    
                          <Tooltip title='Search'>                   
                            <Icon 
                              type="search" 
                              onClick={this.handleSearch} 
                              style={! Boolean(searchValue) ? {display: 'none'}: {} }
                            />
                          </Tooltip> 
                        </>
                      }
                      />
                    </AutoComplete>
                  </Col>
                  <Col span={6}>
                    <Button disabled={! Boolean(searchedExperiment)} style={{float:'right'}} size='large' icon='export'
                      onClick={()=> {
                        const { token, experiments, } = this.props
                        const { queryType } = this.state
                        const { searchedExperiment } = experiments
                        const target = searchedExperiment[TREATMENT_QUERY in searchedExperiment ? TREATMENT_QUERY : COMPOUND_QUERY in searchedExperiment ? COMPOUND_QUERY : PROTEIN_QUERY]
                        const exportType = filterLabel ? PROTEIN_QUERY : TREATMENT_QUERY
                        const compoundId = ! filterLabel ? target.compound_id : 1

                        window.open(`${BACKEND_URL}${EXPORT_EXPERIMENTS_URL}${exportType}/${target.id}/${queryType !== PROTEIN_QUERY ? searchedExperiment.meta.id : ''}?token=${token}&id=${compoundId}`, "_blank")
                      }}>
                      Export
                    </Button>
                    <Button disabled={! Boolean(searchedExperiment) || filterLabel } style={{float:'right'}} size='large' icon='heat-map'
                      onClick={()=> {
                        const { experiments, } = this.props
                        const { searchedExperiment } = experiments
                        const { meta } = searchedExperiment
                        hits.forEach( record => {
                            addCustomProtein({id: `${record.accession_name} (${record.gene_symbol})`, key: String(record.accession_id)})
                            addCustomCompound({id: `${record.treatment_name} (${meta.name})`, key: `${record.treatment_id}-${meta.id}`})
                          }
                        )
                        notification.success({placement: 'bottomRight', message: 'Success', description: 'Successfully added to custom heatmap!', style: {backgroundColor: ERROR_COLOR} })
                        }
                      }>
                      Add to Heatmap
                    </Button>
                  </Col>
              </Row>
              <Row gutter={8} style={! Boolean(searchedExperiment) ? {marginTop: 15} : {display: 'none'}}>
              {this.state.queryType === COMPOUND_QUERY 
                ? <Text>Search by <Text strong>Compound</Text> to find whole cell proteomics data for your compound of interest.</Text>
                : <Text>Search by <Text strong>Protein</Text> gene symbol or Uniprot accession number to find experiments where your protein of interest was measured.</Text>
                }
                <Title level={4}>Welcome to the Fischer Lab’s Proteomics Database</Title>
                  <Paragraph>
                  The goal of this proteomics webtool is to provide a growing open-access resource for quantitative global proteome responses from cell lines treated with various compounds and novel chemical probes.
                  </Paragraph>
                  <Paragraph>
                    <Text strong>This webtool can be used for the:</Text>
                  </Paragraph>
                  <Paragraph>
                  &#8226; Identification of PROTEIN abundance changes in response to different compound treatments
                  </Paragraph>
                  <Paragraph>
                  <img className='protein-img'src={protein} alt='Demo scatter 1'/>
                  </Paragraph>
                  <Paragraph>
                  &#8226; Identification of COMPOUNDS inducing abundance changes of particular proteins
                  </Paragraph>
                  <Paragraph>
                  <img className='compound-img' src={compound} alt='Demo scatter 2'/>
                  </Paragraph>

              </Row>
              <Row gutter={8} style={Boolean(searchedExperiment) ? {marginTop: 15} : {display: 'none'}}>
                <Col span={6} style={searchedExperiment && searchedExperiment.protein ? {display: 'none'} : {}}>
                  <div className='searchDropdownDiv'>
                    <Tooltip title='Multi-plex experiments including this compound'><span className='selectTitle'>Experiment</span></Tooltip>
                    <Select
                      className='searchDropdown'
                      value={searchedExperiment ? searchedExperiment.meta.name: ''}
                      style={{ width: '100%' }}
                      optionFilterProp="children"
                      onChange={this.onExperimentDropdownChange}
                      filterOption={(input, option) => {
                        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      }
                    >
                      {this.getExperimentOptions()}
                    </Select>                  
                    <Tooltip title='All treatments in this multi-plex experiment'><span className='selectTitle'>Treatment</span></Tooltip>
                    <Select
                      className='searchDropdown'
                      value={searchedExperiment && searchedExperiment.compound ? searchedExperiment.compound.name : ''}
                      style={{ width: '100%' }}
                      optionFilterProp="children"
                      onChange={this.onCompoundDropdownChange}
                      filterOption={(input, option) => {
                        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      }
                    >
                      {this.getSecondaryOptions()}
                    </Select>                  
                    <Tooltip title='All cell lines with this compound'><span className='selectTitle'>Cell Line</span></Tooltip>
                    <Select
                      className='searchDropdown'
                      value={searchedExperiment && searchedExperiment.meta ? searchedExperiment.meta.cell_line: ''}
                      style={{ width: '100%' }}
                      optionFilterProp="children"
                      onChange={this.onExperimentDropdownChange}
                      filterOption={(input, option) => {
                        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      }
                    >
                    {this.getTertiaryOptions()}
                    </Select>  
                    <span className='selectTitle'>Concentration (µM)</span>
                    <Input
                      className='searchDropdown'
                      value={searchedExperiment && searchedExperiment.meta ? searchedExperiment.meta.concentration : ''}
                      disabled={true}
                      style={{ marginLeft: 10, width: '92%' }}
                    >
                    </Input>                  
                    <span className='selectTitle'>Time (min)</span>
                    <Input
                      className='searchDropdown'
                      value={searchedExperiment && searchedExperiment.meta ? searchedExperiment.meta.time : ''}
                      disabled={true}
                      style={{ marginLeft: 10, width: '92%' }}      
                     >
                    </Input>                                  
                    </div>                  
                    <div className='searchDropdownDiv'>
                    <span className='selectTitle'>Show Gene Symbol {<br />}</span>
                    <div style={{ marginTop: 5, marginBottom: -5, display:'flex', justifyContent:'center'}}>
                      <Switch checked={this.props.results.showLabel} onChange={(e) => {
                        this.props.setShowLabel(e)
                        }}/>
                     </div>
                      {<br />}

                    <span className='cutoffTitle'>Hit Cut-off Settings{<br />}</span>
                    <div style={{height:10}} />
                    <span className='selectTitle'>Cut-off Shape{<br />}</span>
                    <div style={{ marginTop: 5, marginBottom: -5, display:'flex', justifyContent:'center'}}>
                      <Radio.Group onChange={e => {
                        this.setState({volcanoType: e.target.value})
                        setVolcanoType(e.target.value)
                      }} value={this.state.volcanoType}>
                        <Radio.Button value={HYPERBOLIC}><Tooltip title={<Text style={{color:'white'}}>Cutoff defined by k = log<sub>10</sub> (P-Value) x log<sub>2</sub>(Fold Change)</Text>}
                        >Hyperbolic</Tooltip></Radio.Button>
                        
                        <Radio.Button value={SQUARE}><Tooltip title={<Text style={{color:'white'}}>Cutoffs defined by x = log<sub>10</sub> (P-Value) and y = log<sub>2</sub>(Fold Change)</Text>}
                        >
                          Square</Tooltip></Radio.Button>
                      </Radio.Group>
                     </div>
                      {<br />}

                    <span className='selectTitle'><Tooltip title='Log-transformed P-value from a moderated t-test'>Log<sub>10</sub> P-Value</Tooltip></span>
                    <Row>
                    <Col span={16}>
                    <Slider
                      style={{marginLeft:16}}                     
                      step={1}
                      min={X_SLIDER_MIN}
                      max={X_SLIDER_MAX}
                      onChange={value => this.setState({volcanoP: value})}
                      value={this.state.volcanoP}
                      onAfterChange={value => {
                        setVolcanoP(value)
                    }}
                    />
                    </Col>
                    <Col span={8}>
                        <InputNumber
                          style={{width: '75%', marginLeft: 16}}
                          min={X_SLIDER_MIN}
                          max={X_SLIDER_MAX}
                          step={0.1}
                          value={this.state.volcanoP}
                          onChange={value => {
                            if (! isNaN(value)) {                               
                              this.setState({volcanoP: value ? value : 0})
                              AwesomeDebouncePromise(setVolcanoP(value ? value : 0), 500)
                            } else {
                              console.log(`Bad field: ${value}`)
                            }
                          }}
                        />
                      </Col>
                    </Row>
                    <span className='selectTitle'><Tooltip title='Absolute fold change in protein abundance for treatment vs control'>Fold Change</Tooltip></span>
                    <Row>
                    <Col span={16}>
                    <Slider 
                      style={{marginLeft:16}}
                      step={0.25}
                      min={1}
                      max={10}
                      value={this.state.volcanoFC}
                      onChange={value => this.setState({volcanoFC: value})}
                      onAfterChange={value => {
                        setVolcanoFC(value)
                    }}                    
                    />
                    </Col>
                    <Col span={8}>
                        <InputNumber
                          style={{width: '75%', marginLeft: 16}}
                          min={0}
                          max={Y_SLIDER_MAX}
                          step={0.1}
                          value={this.state.volcanoFC}
                          onChange={value => {
                            if (! isNaN(value)) {                               
                              this.setState({volcanoFC: value ? value : 0})
                              AwesomeDebouncePromise(setVolcanoFC(value ? value : 0), 500)
                            } else {
                              console.log(`Bad field: ${value}`)
                            }
                          }}
                        />
                      </Col>
                    </Row>
                    <span className='selectTitle'><Tooltip title='Number of unique peptides used to identify this protein'>Unique Peptides</Tooltip></span>
                    <Row>
                      <Col span={16}>
                        <Slider 
                          style={{marginLeft:16}}
                          step={1}
                          min={0}
                          max={50}
                          value={this.state.volcanoUnique}
                          onChange={value => this.setState({volcanoUnique: value})}
                          onAfterChange={value => {
                            setVolcanoUnique(value)
                        }}                    
                        />
                      </Col>
                      <Col span={8}>
                        <InputNumber
                          style={{width: '75%', marginLeft: 16}}
                          min={0}
                          max={50}
                          value={this.state.volcanoUnique}
                          onChange={value => {
                            if (! isNaN(value)) {                               
                              this.setState({volcanoUnique: value ? value : 0})
                              setVolcanoUnique(value ? value : 0)
                            } else {
                              console.log(`Bad field: ${value}`)
                            }
                          }}
                        />
                      </Col>
                    </Row>
                    <span className='selectTitle'>X-axis Limit</span>
                    <Row>
                    <Col span={16}>
                    <Slider 
                      style={{marginLeft:16}} 
                      step={0.1}
                      min={X_SLIDER_MIN}
                      max={X_SLIDER_MAX}
                      value={this.state.volcanoX}
                      onChange={value => this.setState({volcanoX: value})}
                      onAfterChange={value => {
                        setVolcanoX(value)
                    }}                    
                    />
                  </Col>
                      <Col span={8}>
                        <InputNumber
                          style={{width: '75%', marginLeft: 16}}
                          min={X_SLIDER_MIN}
                          max={X_SLIDER_MAX}
                          step={0.1}
                          value={this.state.volcanoX}
                          onChange={value => {
                            if (! isNaN(value)) {                               
                              this.setState({volcanoX: value ? value : 0})
                              AwesomeDebouncePromise(setVolcanoX(value ? value : 0), 500)
                            } else {
                              console.log(`Bad field: ${value}`)
                            }
                          }}
                        />
                      </Col>
                    </Row>
                    <span className='selectTitle'>Y-axis Limits</span>
                    <Row>
                    <Col span={8}>
                        <InputNumber
                          style={{width: '75%', marginLeft: 16}}
                          min={Y_SLIDER_MIN}
                          max={Y_SLIDER_MAX}
                          step={0.1}
                          value={this.state.volcanoYMin}
                          onChange={
                            value => {
                              if (! isNaN(value)) {
                                const rounded = _.round(value ? value : 0, 1)
                                this.setState({volcanoYMin: rounded})
                                setVolcanoYMin(rounded)
                              } else {
                                console.log(`Bad field: ${value}`)
                              }
                            }
                          }
                        />
                      </Col>
                      <Col span={8}>
                    <Slider 
                      style={{marginLeft:16}} 
                      range={true}
                      step={0.1}
                      min={Y_SLIDER_MIN}
                      max={Y_SLIDER_MAX}
                      value={[this.state.volcanoYMin, this.state.volcanoYMax]}
                      onChange={value => this.setState({volcanoYMin: value[0], volcanoYMax: value[1]})}
                      onAfterChange={value => {
                        setVolcanoYMin(value[0])
                        setVolcanoYMax(value[1])
                    }}
                    />
                    </Col>
                      <Col span={8}>
                        <InputNumber
                          style={{width: '75%', marginLeft: 16}}
                          min={Y_SLIDER_MIN}
                          max={Y_SLIDER_MAX}
                          step={0.1}
                          value={this.state.volcanoYMax}
                          onChange={
                            value => {
                              if (! isNaN(value)) {     
                                const rounded = _.round(value ? value : 0, 1)
                                this.setState({volcanoYMax: rounded})
                                setVolcanoYMax(rounded)
                              } else {
                                console.log(`Bad field: ${value}`)
                              }
                            }
                          }
                        />
                      </Col>
                    </Row>

                  </div>
                    <div className='searchDropdownDiv'>
                    <span className='selectTitle'>Heatmap Color Bar Range</span>
                    <Select
                      className='searchDropdown'
                      value={heatmapColorSelected}
                      style={{ width: '100%' }}
                      optionFilterProp="children"
                      onChange={val => this.setState({ heatmapColorSelected: val })}
                      filterOption={(input, option) => {
                        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      }
                    >
                      {this.getHeatmapStyleOptions()}
                    </Select>                  
                    <span className='selectTitle'>Heatmap Range:</span>
                    <Row>
                    <Col span={8}>
                        <InputNumber
                          style={{width: '75%', marginLeft: 16}}
                          min={-10}
                          max={10}
                          step={0.1}
                          value={this.state.heatmapMin}
                          onChange={
                            value => {
                              if (! isNaN(value)) { 
                                const rounded = _.round(value ? value : 0, 1)
                                this.setState({heatmapMin: rounded})
                                setHeatmapMin(rounded)
                              } else {
                                console.log(`Bad field: ${value}`)
                              }
                            }
                          }
                        />
                      </Col>
                      <Col span={8}>
                        <Slider 
                          range={true}
                          value={[this.state.heatmapMin, this.state.heatmapMax]}
                          step={0.1}
                          min={-10}
                          max={10}
                          onChange={value => this.setState({heatmapMin: value[0], heatmapMax: value[1]})}
                          onAfterChange={value => {
                            setHeatmapMin(value[0])
                            setHeatmapMax(value[1])
                       }}
                        />
                      </Col>
                      <Col span={8}>
                        <InputNumber
                          style={{width: '75%', marginLeft: 16}}
                          min={-10}
                          max={10}
                          step={0.1}
                          value={this.state.heatmapMax}
                          onChange={
                            value => {
                              if (! isNaN(value)) {                                 
                                const rounded = _.round(value ? value : 0, 1)
                                this.setState({heatmapMax: rounded})
                                setHeatmapMax(rounded)
                              } else {
                                console.log(`Bad field: ${value}`)
                              }
                            }
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col span={searchedExperiment && searchedExperiment.protein ? 24 : 18}>
                  <Row gutter={ 8 }>
                  {searchedExperiment && (searchedExperiment.compound ||searchedExperiment.protein)  ? 
                  <Volcano 
                    hits={hitData} 
                    token={this.props.token}
                  /> : null}
                  </Row>
                  <Row>
                    {hits.length && ! Boolean(filterLabel)  ? 
                  <Heatmap 
                    token={this.props.token} 
                    hits={hits} 
                    colorScheme={heatmapColorSelected} 
                  /> : null}
                  </Row>
                </Col>
              </Row>
              {hits.length && ! Boolean(filterLabel) ?
                <Row>
                    <Table 
                      style={{marginTop:30}}
                      columns={this.columns} 
                      rowKey='id' 
                      dataSource={_.map(hits, (data => data))} 
                      />
                </Row>
              : null}
              </>

        } else {
          return <>
              <Typography>
                <Title level={4}>Welcome to the Fischer Lab’s Proteomics Database</Title>
                <Paragraph>
                The goal of this proteomics webtool is to provide a growing open-access resource for quantitative global proteome responses from cell lines treated with various compounds and novel chemical probes.
                </Paragraph>
                <Paragraph>
                To access the database please signup using a gmail address as we are currently only supporting gmail as means of authentication.
                After registration it will take up to 72 hrs for us to approve your request, and once approved you will have full access to the database via this webtool and also via a REST API.
                </Paragraph>
                <Paragraph>
                Further instructions can be found on our lab website.
                </Paragraph>
                <Title level={4}>Fischer Lab Proteomics Database Bulletin Board</Title>
                <Paragraph>
                To subscribe to the Fischer lab’s proteomics database bulletin board for updates on future data release please email: {<a href={'mailto:fischerlab-proteomics+subscribe@crystal.harvard.edu'}>fischerlab-proteomics+subscribe@crystal.harvard.edu</a>} 
                </Paragraph>
                <Paragraph>
                Archived messages can be browsed and searched at the list homepage: <a href={'https://groups.google.com/a/crystal.harvard.edu/g/fischerlab-proteomics'}>https://groups.google.com/a/crystal.harvard.edu/g/fischerlab-proteomics</a>
                </Paragraph>
            </Typography>
            <div>
              <Modal
                title="DFCI Proteomics"
                visible={this.props.showLogin}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={null}
                style={{display: 'flex', justifyContent: 'center',  alignItems: 'center'}}>
                  <GoogleButton onClick={() => this.handleOk()}/>
              </Modal>
            </div>
          </>
        }
    }
  
}

const mapStateToProps = ( { experiments, results }) => {
  return { 
    experiments,
    results,
  }
}

export default connect (mapStateToProps, {
  getResultDataRequest,
  getAllCompounds,
  getAllProteins,
  getExperimentResultDataRequest,
  setHeatmapMax,
  setHeatmapMin,
  setVolcanoX,
  setVolcanoYMin,
  setVolcanoYMax,
  setVolcanoP,
  setVolcanoFC,
  setVolcanoUnique,
  setVolcanoType,
  setClickedAsync,
  setLoading,
  addCustomProtein,
  addCustomCompound,
  setShowLabel,
  setSearchedExperiment,
}) (Home)