import axios from 'axios'
import { notification } from 'antd'
import { BACKEND_URL, ERROR_COLOR } from '../shared/constants'

const GET_METHOD = 'get'
const POST_METHOD = 'post'
const PATCH_METHOD = 'patch'
const DELETE_METHOD = 'delete'

class ApiService {
    constructor(baseUrl) {
        this.baseUrl = baseUrl
    }

    clusterHeatmapRequest = async (token, accessions, compoundExperiments) => {
        try {
            const clusterResponse = await axios({
                method: POST_METHOD,
                url: `${BACKEND_URL}/custom_heatmap`,
                headers: {'Authorization': `Bearer ${token}`},
                data: {
                    accessions,
                    compoundExperiments,
                }
            })
            return clusterResponse
        } catch (e) {
            switch(e.response.status) {
                case 400:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Parameter Error', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 401:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Login Error', description: 'You must be logged in to do that.', style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 403:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Permissions Error', description: 'Permissions issue - try re-logging in.', style: {backgroundColor: ERROR_COLOR} })                    
                    break
                    
                case 404:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Not Found', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
    
                case 409:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Conflict', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break

                case 429:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Limit Exceeded', description: 'You have exceeded API limitations. Try again in an hour.', style: {backgroundColor: ERROR_COLOR} })
                    break                    

                default:
                    console.log(e.response.status)
            }
        }
    }

    addExperimentToGroup = async (token, experimentId, groupId) => {
        try {
            const addResponse = await axios({
                method: POST_METHOD,
                url: `${BACKEND_URL}/group_experiments/${experimentId}/${groupId}`,
                headers: {'Authorization': `Bearer ${token}`},
            })
            notification.success({placement: 'bottomRight', message: 'Update Success', description: `Successfully added experiment ${addResponse.data.experiment.name} to group ${addResponse.data.group.name}`, style: {backgroundColor: ERROR_COLOR} })
            return addResponse
        } catch (e) {
            switch(e.response.status) {
                case 400:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Parameter Error', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 401:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Login Error', description: 'You must be logged in to do that.', style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 403:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Permissions Error', description: 'Permissions issue - try re-logging in.', style: {backgroundColor: ERROR_COLOR} })                    
                    break
                    
                case 404:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Not Found', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break

                case 409:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Conflict', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
  
                case 429:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Limit Exceeded', description: 'You have exceeded API limitations. Try again in an hour.', style: {backgroundColor: ERROR_COLOR} })
                    break                    
                      
                default:
                    console.log(e.response.status)
            }
        }
    }

    removeExperimentFromGroup = async (token, experimentId, groupId) => {
        try {
            const removeResponse = await axios({
                method: DELETE_METHOD,
                url: `${BACKEND_URL}/group_experiments/${experimentId}/${groupId}`,
                headers: {'Authorization': `Bearer ${token}`},
            })
            notification.success({placement: 'bottomRight', message: 'Remove Success', description: `Successfully removed experiment ${removeResponse.data.experiment.name} from group ${removeResponse.data.group.name}`, style: {backgroundColor: ERROR_COLOR} })
            return removeResponse
        } catch (e) {
            switch(e.response.status) {
                case 400:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Parameter Error', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 401:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Login Error', description: 'You must be logged in to do that.', style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 403:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Permissions Error', description: 'Permissions issue - try re-logging in.', style: {backgroundColor: ERROR_COLOR} })                    
                    break
                    
                case 404:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Not Found', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break

                case 409:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Conflict', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
             
                case 429:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Limit Exceeded', description: 'You have exceeded API limitations. Try again in an hour.', style: {backgroundColor: ERROR_COLOR} })
                    break                    
        
                default:
                    console.log(e.response.status)
            }
        }
    }

    createExperiment = async (token, data) => {
        try {
            const createResponse = await axios({
                method: POST_METHOD,
                url: `${BACKEND_URL}/experiments`,
            headers: {'Authorization': `Bearer ${token}`,
            },
                data,
              })
              notification.success({placement: 'bottomRight', message: 'Create Success', description: `Successfully created ${createResponse.data.name}`, style: {backgroundColor: ERROR_COLOR} })
            return createResponse

        } catch (e) {
            switch(e.response.status) {
                case 400:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Parameter Error', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 401:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Login Error', description: 'You must be logged in to do that.', style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 403:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Permissions Error', description: 'Permissions issue - try re-logging in.', style: {backgroundColor: ERROR_COLOR} })                    
                    break
                    
                case 404:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Not Found', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
    
                case 409:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Conflict', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                   
                case 429:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Limit Exceeded', description: 'You have exceeded API limitations. Try again in an hour.', style: {backgroundColor: ERROR_COLOR} })
                    break                    

                default:
                    console.log(e.response.status)
            }
        }
    }
    bulkCreateExperiment = async (token, data) => {
        try {
            const createResponse = await axios({
                method: POST_METHOD,
                url: `${BACKEND_URL}/experiments_bulk`,
            headers: {'Authorization': `Bearer ${token}`,
            },
                data,
              })
              notification.success({placement: 'bottomRight', message: 'Create Success', description: `Successfully created experiments.`, style: {backgroundColor: ERROR_COLOR} })
            return createResponse

        } catch (e) {
            switch(e.response.status) {
                case 400:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Parameter Error', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 401:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Login Error', description: 'You must be logged in to do that.', style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 403:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Permissions Error', description: 'Permissions issue - try re-logging in.', style: {backgroundColor: ERROR_COLOR} })                    
                    break
                    
                case 404:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Not Found', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
    
                case 409:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Conflict', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                   
                case 429:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Limit Exceeded', description: 'You have exceeded API limitations. Try again in an hour.', style: {backgroundColor: ERROR_COLOR} })
                    break                    
 
                default:
                    console.log(e.response.status)
            }
        }
    }

    updateExperiment = async (token, data) => {
        const id = data.id
        delete data.id

        try {
            const updateResponse = await axios({
                method: PATCH_METHOD,
                url: `${BACKEND_URL}/experiments/${id}`,
            headers: {'Authorization': `Bearer ${token}`,
            },
                data,
              })
              notification.success({placement: 'bottomRight', message: 'Update Success', description: `Successfully updated ${updateResponse.data.name}`, style: {backgroundColor: ERROR_COLOR} })

            return updateResponse

        } catch (e) {
            switch(e.response.status) {
                case 400:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Parameter Error', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                
                case 401:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Login Error', description: 'You must be logged in to do that.', style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 403:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Permissions Error', description: 'Permissions issue - try re-logging in.', style: {backgroundColor: ERROR_COLOR} })                    
                    break

                case 404:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Not Found', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
    
                case 409:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Conflict', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break

                case 429:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Limit Exceeded', description: 'You have exceeded API limitations. Try again in an hour.', style: {backgroundColor: ERROR_COLOR} })
                    break                    

                default:
                    console.log(e.response.status)
            }
        }
    }

    getExperiments = async (token) => {
        try {
            const allUsers = await axios({
                method: GET_METHOD,
                url: `${BACKEND_URL}/experiments`,
                headers: {'Authorization': `Bearer ${token}`},
            })
            return allUsers
        } catch (e) {
            switch(e.response.status) {
                case 400:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Parameter Error', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 401:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Login Error', description: 'You must be logged in to do that.', style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 403:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Permissions Error', description: 'Permissions issue - try re-logging in.', style: {backgroundColor: ERROR_COLOR} })                    
                    break
                    
                case 404:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Not Found', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
    
                case 409:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Conflict', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                   
                case 429:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Limit Exceeded', description: 'You have exceeded API limitations. Try again in an hour.', style: {backgroundColor: ERROR_COLOR} })
                    break                    
 
                default:
                    console.log(e.response.status)
            }
        }
    }

    getSingleExperiment = async (token, experimentId) => {
        try {
            const allUsers = await axios({
                method: GET_METHOD,
                url: `${BACKEND_URL}/experiments/${experimentId}`,
                headers: {'Authorization': `Bearer ${token}`},
            })
            return allUsers
        } catch (e) {
            switch(e.response.status) {
                case 400:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Parameter Error', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 401:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Login Error', description: 'You must be logged in to do that.', style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 403:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Permissions Error', description: 'Permissions issue - try re-logging in.', style: {backgroundColor: ERROR_COLOR} })                    
                    break
                    
                case 404:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Not Found', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
    
                case 409:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Conflict', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                   
                case 429:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Limit Exceeded', description: 'You have exceeded API limitations. Try again in an hour.', style: {backgroundColor: ERROR_COLOR} })
                    break                    
 
                default:
                    console.log(e.response.status)
            }
        }
    }

    getResultData = async (token, targetId, queryType) => {
        try {
            const resultData = await axios({
                method: GET_METHOD,
                url: `${BACKEND_URL}/results/${queryType}/${targetId}`,
                headers: {'Authorization': `Bearer ${token}`},
            })
            return resultData

        } catch (e) {
            switch(e.response.status) {
                case 400:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Parameter Error', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 401:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Login Error', description: 'You must be logged in to do that.', style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 403:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Permissions Error', description: 'Permissions issue - try re-logging in.', style: {backgroundColor: ERROR_COLOR} })                    
                    break
                    
                case 404:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Not Found', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
    
                case 409:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Conflict', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break

                case 423:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Not Ready', description: 'Results are still uploading. Please check back later.', style: {backgroundColor: ERROR_COLOR} })
                    break
                   
                case 429:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Limit Exceeded', description: 'You have exceeded API limitations. Try again in an hour.', style: {backgroundColor: ERROR_COLOR} })
                    break                    
 
                default:
                    console.log(e.response.status)
            }
        }
    }

    getExperimentResultData = async (token, targetId, queryType, experimentId, suffix='') => {
        try {
            const resultData = await axios({
                method: GET_METHOD,
                url: `${BACKEND_URL}/results/${queryType}/${targetId}/${experimentId}${suffix}`,
                headers: {'Authorization': `Bearer ${token}`},
            })
            return resultData
        } catch (e) {
            switch(e.response.status) {
                case 400:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Parameter Error', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 401:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Login Error', description: 'You must be logged in to do that.', style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 403:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Permissions Error', description: 'Permissions issue - try re-logging in.', style: {backgroundColor: ERROR_COLOR} })                    
                    break
                    
                case 404:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Not Found', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break

                case 409:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Conflict', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break

                case 423:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Not Ready', description: 'Results are still uploading. Please check back later.', style: {backgroundColor: ERROR_COLOR} })
                    break

                case 429:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Limit Exceeded', description: 'You have exceeded API limitations. Try again in an hour.', style: {backgroundColor: ERROR_COLOR} })
                    break                    

                default:
                    console.log(e.response.status)
            }
        }    
    }

    createConfiguration = async (token, data) => {
        try {
            const createResponse = await axios({
                method: POST_METHOD,
                url: `${BACKEND_URL}/system_configuration`,
            headers: {'Authorization': `Bearer ${token}`,
            },
                data,
              })
              notification.success({placement: 'bottomRight', message: 'Create Success', description: `Successfully created system configuration.`, style: {backgroundColor: ERROR_COLOR} })

            return createResponse

        } catch (e) {
            switch(e.response.status) {
                case 400:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Parameter Error', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 401:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Login Error', description: 'You must be logged in to do that.', style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 403:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Permissions Error', description: 'Permissions issue - try re-logging in.', style: {backgroundColor: ERROR_COLOR} })                    
                    break
                    
                case 404:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Not Found', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
    
                case 409:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Conflict', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                   
                case 429:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Limit Exceeded', description: 'You have exceeded API limitations. Try again in an hour.', style: {backgroundColor: ERROR_COLOR} })
                    break                    
 
                default:
                    console.log(e.response.status)
            }
        }
    }
    deleteConfiguration = async (token, configId) => {
        try {
            const deleteResponse = await axios({
                method: DELETE_METHOD,
                url: `${BACKEND_URL}/system_configuration/${configId}`,
            headers: {'Authorization': `Bearer ${token}`},
            })
            notification.success({placement: 'bottomRight', message: 'Delete Success', description: 'Successfully deleted system configuration.', style: {backgroundColor: ERROR_COLOR} })

            return deleteResponse

        } catch (e) {
            switch(e.response.status) {
                case 400:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Parameter Error', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 401:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Login Error', description: 'You must be logged in to do that.', style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 403:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Permissions Error', description: 'Permissions issue - try re-logging in.', style: {backgroundColor: ERROR_COLOR} })                    
                    break

                case 404:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Not Found', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
    
                case 409:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Conflict', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break

                case 429:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Limit Exceeded', description: 'You have exceeded API limitations. Try again in an hour.', style: {backgroundColor: ERROR_COLOR} })
                    break                    

                default:
                    console.log(e.response.status)
            }
        }
    }

    updateConfiguration = async (token, data) => {
        const id = data.id
        delete data.id

        try {
            const updateResponse = await axios({
                method: PATCH_METHOD,
                url: `${BACKEND_URL}/system_configuration/${id}`,
            headers: {'Authorization': `Bearer ${token}`,
            },
                data,
              })
              notification.success({placement: 'bottomRight', message: 'Update Success', description: 'Successfully updated system configuration.', style: {backgroundColor: ERROR_COLOR} })

            return updateResponse

        } catch (e) {
            switch(e.response.status) {
                case 400:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Parameter Error', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                
                case 401:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Login Error', description: 'You must be logged in to do that.', style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 403:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Permissions Error', description: 'Permissions issue - try re-logging in.', style: {backgroundColor: ERROR_COLOR} })                    
                    break

                case 404:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Not Found', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
    
                case 409:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Conflict', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break

                case 429:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Limit Exceeded', description: 'You have exceeded API limitations. Try again in an hour.', style: {backgroundColor: ERROR_COLOR} })
                    break                    

                default:
                    console.log(e.response.status)
            }
        }
    }

    getConfigurations = async (token) => {
        try {
            const allUsers = await axios({
                method: GET_METHOD,
                url: `${BACKEND_URL}/system_configuration`,
                headers: {'Authorization': `Bearer ${token}`},
            })
            return allUsers
        } catch (e) {
            switch(e.response.status) {
                case 400:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Parameter Error', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 401:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Login Error', description: 'You must be logged in to do that.', style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 403:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Permissions Error', description: 'Permissions issue - try re-logging in.', style: {backgroundColor: ERROR_COLOR} })                    
                    break
                    
                case 404:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Not Found', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
    
                case 409:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Conflict', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                   
                case 429:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Limit Exceeded', description: 'You have exceeded API limitations. Try again in an hour.', style: {backgroundColor: ERROR_COLOR} })
                    break                    
 
                default:
                    console.log(e.response.status)
            }
        }
    }

    updateUser = async (token, data) => {
        const id = data.id
        delete data.id

        try {
            const updateResponse = await axios({
                method: PATCH_METHOD,
                url: `${BACKEND_URL}/users/${id}`,
            headers: {'Authorization': `Bearer ${token}`,
            },
                data,
              })
              notification.success({placement: 'bottomRight', message: 'Update Success', description: `Successfully updated user ${updateResponse.data.email}`, style: {backgroundColor: ERROR_COLOR} })
            return updateResponse

        } catch (e) {
            switch(e.response.status) {
                case 400:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Parameter Error', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                
                case 401:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Login Error', description: 'You must be logged in to do that.', style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 403:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Permissions Error', description: 'Permissions issue - try re-logging in.', style: {backgroundColor: ERROR_COLOR} })
                    break

                case 404:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Not Found', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
    
                case 409:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Conflict', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break

                case 429:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Limit Exceeded', description: 'You have exceeded API limitations. Try again in an hour.', style: {backgroundColor: ERROR_COLOR} })
                    break                    

                default:
                    console.log(e.response.status)
            }
        }
    }
    
    updateGroup = async (token, data) => {
        const id = data.id
        delete data.id

        try {
            const updateResponse = await axios({
                method: PATCH_METHOD,
                url: `${BACKEND_URL}/groups/${id}`,
            headers: {'Authorization': `Bearer ${token}`,
            },
                data,
              })
              notification.success({placement: 'bottomRight', message: 'Update Success', description: `Successfully updated group ${updateResponse.data.name}`, style: {backgroundColor: ERROR_COLOR} })

            return updateResponse

        } catch (e) {
            switch(e.response.status) {
                case 400:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Parameter Error', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 401:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Login Error', description: 'You must be logged in to do that.', style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 403:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Permissions Error', description: 'Permissions issue - try re-logging in.', style: {backgroundColor: ERROR_COLOR} })                    
                    break

                case 404:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Not Found', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
    
                case 409:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Conflict', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break

                case 429:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Limit Exceeded', description: 'You have exceeded API limitations. Try again in an hour.', style: {backgroundColor: ERROR_COLOR} })
                    break                    

                default:
                    console.log(e.response.status)
            }
        }
    }
    deleteGroup = async (token, groupId) => {
        try {
            const deleteResponse = await axios({
                method: DELETE_METHOD,
                url: `${BACKEND_URL}/groups/${groupId}`,
            headers: {'Authorization': `Bearer ${token}`},
            })
            notification.success({placement: 'bottomRight', message: 'Delete Success', description: 'Successfully deleted group.', style: {backgroundColor: ERROR_COLOR} })
            return deleteResponse

        } catch (e) {
            switch(e.response.status) {
                case 400:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Parameter Error', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 401:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Login Error', description: 'You must be logged in to do that.', style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 403:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Permissions Error', description: 'Permissions issue - try re-logging in.', style: {backgroundColor: ERROR_COLOR} })                    
                    break

                case 404:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Not Found', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
    
                case 409:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Conflict', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break

                case 429:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Limit Exceeded', description: 'You have exceeded API limitations. Try again in an hour.', style: {backgroundColor: ERROR_COLOR} })
                    break                    

                default:
                    console.log(e.response.status)
            }
        }
    }
    
    deleteUser = async (token, userId) => {
        try {
            const deleteResponse = await axios({
                method: DELETE_METHOD,
                url: `${BACKEND_URL}/users/${userId}`,
            headers: {'Authorization': `Bearer ${token}`},
            })
            notification.success({placement: 'bottomRight', message: 'Delete Success', description: 'Successfully deleted user.', style: {backgroundColor: ERROR_COLOR} })
            return deleteResponse

        } catch (e) {
            switch(e.response.status) {
                case 400:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Parameter Error', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 401:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Login Error', description: 'You must be logged in to do that.', style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 403:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Permissions Error', description: 'Permissions issue - try re-logging in.', style: {backgroundColor: ERROR_COLOR} })                    
                    break

                case 404:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Not Found', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
    
                case 409:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Conflict', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break

                case 429:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Limit Exceeded', description: 'You have exceeded API limitations. Try again in an hour.', style: {backgroundColor: ERROR_COLOR} })
                    break                    

                default:
                    console.log(e.response.status)
            }
        }
    }

    createGroup = async (token, data) => {
        try {
            const updateResponse = await axios({
                method: POST_METHOD,
                url: `${BACKEND_URL}/groups`,
            headers: {'Authorization': `Bearer ${token}`,
            },
                data,
              })
              notification.success({placement: 'bottomRight', message: 'Create Success', description: `Successfully created group ${data.name}.`, style: {backgroundColor: ERROR_COLOR} })

            return updateResponse

        } catch (e) {
            switch(e.response.status) {
                case 400:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Parameter Error', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 401:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Login Error', description: 'You must be logged in to do that.', style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 403:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Permissions Error', description: 'Permissions issue - try re-logging in.', style: {backgroundColor: ERROR_COLOR} })                    
                    break
                    
                case 404:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Not Found', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
    
                case 409:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Conflict', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break

                case 429:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Limit Exceeded', description: 'You have exceeded API limitations. Try again in an hour.', style: {backgroundColor: ERROR_COLOR} })
                    break                    

                default:
                    console.log(e.response.status)
            }
        }
    }

    getUsers = async (token) => {
        try {
            const allUsers = await axios({
                method: GET_METHOD,
                url: `${BACKEND_URL}/users`,
                headers: {'Authorization': `Bearer ${token}`},
            })
            return allUsers
        } catch (e) {
            switch(e.response.status) {
                case 400:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Parameter Error', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 401:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Login Error', description: 'You must be logged in to do that.', style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 403:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Permissions Error', description: 'Permissions issue - try re-logging in.', style: {backgroundColor: ERROR_COLOR} })                    
                    break
                    
                case 404:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Not Found', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
    
                case 409:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Conflict', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 429:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Limit Exceeded', description: 'You have exceeded API limitations. Try again in an hour.', style: {backgroundColor: ERROR_COLOR} })
                    break                    

                default:
                    console.log(e.response.status)
            }
        }
    }

    getGroups = async (token) => {
        try {
            const allGroups = await axios({
                method: GET_METHOD,
                url: `${BACKEND_URL}/groups`,
                headers: {'Authorization': `Bearer ${token}`},
            })
            return allGroups
        } catch (e) {
            switch(e.response.status) {
                case 400:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Parameter Error', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 401:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Login Error', description: 'You must be logged in to do that.', style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 403:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Permissions Error', description: 'Permissions issue - try re-logging in.', style: {backgroundColor: ERROR_COLOR} })                    
                    break
                    
                case 404:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Not Found', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
    
                case 409:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Conflict', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 429:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Limit Exceeded', description: 'You have exceeded API limitations. Try again in an hour.', style: {backgroundColor: ERROR_COLOR} })
                    break                    

                default:
                    console.log(e.response.status)
            }
        }
    }

    addUserToGroup = async (token, userId, groupId) => {
        try {
            const addResponse = await axios({
                method: POST_METHOD,
                url: `${BACKEND_URL}/user_groups/${userId}/${groupId}`,
                headers: {'Authorization': `Bearer ${token}`},
            })
            notification.success({placement: 'bottomRight', message: 'Update Success', description: `Successfully added user ${addResponse.data.user.email} to group ${addResponse.data.group.name}`, style: {backgroundColor: ERROR_COLOR} })

            return addResponse
        } catch (e) {
            switch(e.response.status) {
                case 400:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Parameter Error', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 401:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Login Error', description: 'You must be logged in to do that.', style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 403:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Permissions Error', description: 'Permissions issue - try re-logging in.', style: {backgroundColor: ERROR_COLOR} })                    
                    break
                    
                case 404:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Not Found', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
    
                case 409:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Conflict', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 429:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Limit Exceeded', description: 'You have exceeded API limitations. Try again in an hour.', style: {backgroundColor: ERROR_COLOR} })
                    break                    

                default:
                    console.log(e.response.status)
            }
        }
    }

    removeUserFromGroup = async (token, userId, groupId) => {
        try {
            const removeResponse = await axios({
                method: DELETE_METHOD,
                url: `${BACKEND_URL}/user_groups/${userId}/${groupId}`,
                headers: {'Authorization': `Bearer ${token}`},
            })
            notification.success({placement: 'bottomRight', message: 'Remove Success', description: `Successfully removed user ${removeResponse.data.user.email} from group ${removeResponse.data.group.name}`, style: {backgroundColor: ERROR_COLOR} })

            return removeResponse
        } catch (e) {
            switch(e.response.status) {
                case 400:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Parameter Error', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 401:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Login Error', description: 'You must be logged in to do that.', style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 403:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Permissions Error', description: 'Permissions issue - try re-logging in.', style: {backgroundColor: ERROR_COLOR} })                    
                    break
                    
                case 404:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Not Found', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
    
                case 409:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Conflict', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                default:
                    console.log(e.response.status)
            }
        }
    }

    logout = async (token) => {
        const logout = await axios({
            method: GET_METHOD,
            url: `${BACKEND_URL}/logout`,
            headers: {'Authorization': `Bearer ${token}`},
        })
        return logout
    }

    getCompounds = async (token) => {
        try {
            const allCompounds = await axios({
                method: GET_METHOD,
                url: `${BACKEND_URL}/compounds`,
                headers: {'Authorization': `Bearer ${token}`},
            })
            return allCompounds
        } catch (e) {
            switch(e.response.status) {
                case 400:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Parameter Error', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 401:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Login Error', description: 'You must be logged in to do that.', style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 403:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Permissions Error', description: 'Permissions issue - try re-logging in.', style: {backgroundColor: ERROR_COLOR} })                    
                    break
                    
                case 404:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Not Found', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break

                case 409:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Conflict', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 429:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Limit Exceeded', description: 'You have exceeded API limitations. Try again in an hour.', style: {backgroundColor: ERROR_COLOR} })
                    break                    

                default:
                    console.log(e.response.status)
            }
        }
    }

    getExperimentCompounds = async (token) => {
        try {
            const allCompounds = await axios({
                method: GET_METHOD,
                url: `${BACKEND_URL}/experiment_compounds`,
                headers: {'Authorization': `Bearer ${token}`},
            })
            return allCompounds
        } catch (e) {
            switch(e.response.status) {
                case 400:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Parameter Error', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 401:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Login Error', description: 'You must be logged in to do that.', style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 403:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Permissions Error', description: 'Permissions issue - try re-logging in.', style: {backgroundColor: ERROR_COLOR} })                    
                    break
                    
                case 404:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Not Found', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break

                case 409:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Conflict', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 429:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Limit Exceeded', description: 'You have exceeded API limitations. Try again in an hour.', style: {backgroundColor: ERROR_COLOR} })
                    break                    

                default:
                    console.log(e.response.status)
            }
        }
    }

    getProteins = async (token) => {
        try {
            const allCompounds = await axios({
                method: GET_METHOD,
                url: `${BACKEND_URL}/proteins`,
                headers: {'Authorization': `Bearer ${token}`},
            })
            return allCompounds
        } catch (e) {
            switch(e.response.status) {
                case 400:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Parameter Error', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 401:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Login Error', description: 'You must be logged in to do that.', style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 403:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Permissions Error', description: 'Permissions issue - try re-logging in.', style: {backgroundColor: ERROR_COLOR} })                    
                    break
                    
                case 404:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Not Found', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break

                case 409:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Conflict', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 429:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Limit Exceeded', description: 'You have exceeded API limitations. Try again in an hour.', style: {backgroundColor: ERROR_COLOR} })
                    break                    

                default:
                    console.log(e.response.status)
            }
        }
    }

    deleteExperiment = async (token, experimentId) => {
        try {
            const deleteResponse = await axios({
                method: DELETE_METHOD,
                url: `${BACKEND_URL}/experiments/${experimentId}`,
            headers: {'Authorization': `Bearer ${token}`},
            })
            notification.success({placement: 'bottomRight', message: 'Delete Success', description: 'Delete request recieved.', style: {backgroundColor: ERROR_COLOR} })
            return deleteResponse

        } catch (e) {
            switch(e.response.status) {
                case 400:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Parameter Error', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 401:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Login Error', description: 'You must be logged in to do that.', style: {backgroundColor: ERROR_COLOR} })
                    break
                    
                case 403:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Permissions Error', description: 'Permissions issue - try re-logging in.', style: {backgroundColor: ERROR_COLOR} })                    
                    break

                case 404:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Not Found', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break
    
                case 409:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Conflict', description: `Data conflict: ${e.response.data.message}`, style: {backgroundColor: ERROR_COLOR} })
                    break

                case 429:
                    notification.error({duration:0, placement: 'bottomRight', message: 'Limit Exceeded', description: 'You have exceeded API limitations. Try again in an hour.', style: {backgroundColor: ERROR_COLOR} })
                    break                    

                default:
                    console.log(e.response.status)
            }
        }
    }
}


export default new ApiService()